import React from 'react'
import './UserPosition.css';
import Popover from '@mui/material/Popover';
import { Box, Button, TextField } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {SocketContext}  from '../../utils/SocketContext';
import { placeOrder, delMarketPositions } from "../../features/slices/users/userSlice";
import { updateChain } from "../../features/slices/table/tablePrefrenceSlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment-timezone';
import { sortRows, sum, rowGrouper } from "./tableFunctions";

const rowClass = (n) => {
  if (n && n.tPNL)
    return (n.tPNL >= 0 ? "textclr-green" : "textclr-red")
  return (n >= 0 ? "textclr-green" : "textclr-red")
}

const handleSort = (sortColumns, setSortColumn) => {
  setSortColumn([...sortColumns]);
};

const UserPosition = () => {
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set());
  const [sData, setSData] = React.useState({});
  const [order, setOrder] = React.useState({});
  const [rData, setRData] = React.useState([]);
  const [insExpData, setInsExpData] = React.useState({});
  const [totalPNLDict, setTotalPNLDict] = React.useState({});
  const [totalSumPNLDict, setTotalSumPNLDict] = React.useState({});
  const [sortColumn, setSortColumn] = React.useState([]);
  const marktPositions = useSelector((state) => state.user.marktPositions);
  const updateInterval = useSelector((state) => state.socket.updateInterval);
  const start  =  moment.unix(useSelector((state) => state.socket.start)).tz("Asia/Kolkata");
  const end  =  moment.unix(useSelector((state) => state.socket.end)).tz("Asia/Kolkata");
  const isDayOfWeek  = useSelector((state) => state.socket.isDayOfWeek);
  const [anchorBS, setAnchorBS] = React.useState(null);

  const GroupRow = React.useCallback(({ id, row }) => {
    return (<span className={rowClass(totalPNLDict[row.groupKey])}>{ insExpData[row.groupKey] ? insExpData[row.groupKey][id] ?? 0 : 0}</span>)
  } , [insExpData, totalPNLDict])

  const handlePriceChange = (e) => {
    const value = e && e.currentTarget && e.currentTarget.value ? e.currentTarget.value : null
    if(value){
      setOrder((o)=> ({...o, "price": value }));
    }
  }  

  const handleQtyChange = (e) => {
    const value = e && e.currentTarget && e.currentTarget.value ? e.currentTarget.value : null
    if(value){
      setOrder((o)=> ({...o, "qty": value }));
    }
  }  

  const show_chain = React.useCallback((instrument, expiry) => {
    dispatch(updateChain({
      "instrument": instrument,
      "expiry": expiry
    }))
  }, [])

  const memoizedColumns =  React.useMemo( () => {
    return [
      {
        key: "instrumentExp",
        name: "InstrumentExp",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "string",
        renderGroupCell: (row) => {
          const r = row.childRows[0]
          return <div onDoubleClick={()=>{show_chain(r.instrument, r.expiry)}} className={rowClass(totalPNLDict[row.groupKey])} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
        },
        renderSummaryCell: () => 'Total PNL'
      },
      {
        key: "id",
        name: "Instrument",
        resizable: true,
        sortable: true,
        minWidth: 155,
        cellClass: "insExp",
        type: "string"
      },
      {
        key: "qty",
        name: "Qty",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{sum(row.childRows, "qty")}</span>,
        minWidth: 60,
        type: "number"
      },
      {
        key: "avgPrice",
        name: "Avg. Price",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "number"
      },
      {
        key: "ltp",
        name: "LTP",
        resizable: true,
        sortable: true,
        minWidth: 60,
        renderCell: ({row}) => parseFloat(row?.ltp ?? 0).toFixed(2),
        renderGroupCell: (row)=> (<GroupRow id={'fprc'} row={row} />),
        type: "number"
      },
      {
        key: "tPNL",
        name: "TotalPNL",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.tPNL ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{parseFloat(totalPNLDict[row.groupKey]).toFixed(2)}</span>,
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.tpnl ?? 0).toFixed(2)}</span>,
        minWidth: 125,
        type: "number"
      },
      {
        key: "delta",
        name: "Exposure (in Lakhs)",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.delta ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{parseFloat(sum(row.childRows, "delta")).toFixed(2)}</span>,
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.delta ?? 0).toFixed(2)}</span>,
        minWidth: 50,
        type: "number"
      },
      {
        key: "vega",
        name: "Vega",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseInt(row?.vega ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{sum(row.childRows, "vega")}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.vega ?? 0)}</span>,
        minWidth: 50,
        type: "number"
      },
      {
        key: "prem",
        name: "Premium",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseInt(row?.prem ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{sum(row.childRows, "prem")}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.prem ?? 0)}</span>,
        minWidth: 75,
        type: "number"
      },
      {
        key: "theta",
        name: "Theta",
        resizable: true,
        sortable: true,
        minWidth: 75,
        renderCell: ({row}) => parseInt(row?.theta ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{parseInt(sum(row.childRows, "theta"))}</span>,
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.theta ?? 0)}</span>,
        type: "number"
      },
      {
        key: "iv",
        name: "IV",
        resizable: true,
        sortable: true,
        minWidth: 40,
        renderGroupCell: (row)=> (<GroupRow id={'iv'} row={row} />),
        type: "number"
      },
      // {
      //   key: "futPrice",
      //   name: "FutPrice",
      //   resizable: true,
      //   sortable: true,
      //   renderGroupCell: ({ row }) => {
      //  
      //     return <span className={rowClass(totalPNLDict[row.groupKey])}>{row.childRows[0].futPrice}</span>
      //   },
      //   minWidth: 80,
      //   type: "number"
      // },
      {
        key: "rPNL",
        name: "RealizedPNL",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.rPNL ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{parseFloat(sum(row.childRows, "rPNL")).toFixed(2)}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.rPNL ?? 0).toFixed(2)}</span>,
        minWidth: 125,
        type: "number"
      },
      {
        key: "urPNL",
        name: "UnrealizedPNL",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.urPNL ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(totalPNLDict[row.groupKey])}>{parseFloat(sum(row.childRows, "urPNL")).toFixed(2)}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.urPNL ?? 0).toFixed(2)}</span>,
        minWidth: 125,
        type: "number"
      },
      {
        key: "del",
        name: "Remove",
        resizable: true,
        minWidth: 20,
        cellClass: "insExp",
        renderGroupCell: (row) => (<Button onClick={()=> dispatch(delMarketPositions({instrument: row.groupKey}))} size={'small'} sx={{m:0, p:0}}><CancelIcon color='error'/></Button>),
        renderCell: (row) => (<Button onClick={()=> dispatch(delMarketPositions({instrument: row.row.id}))} size={'small'} sx={{m:0, p:0}}><CancelIcon color='error'/></Button>)
      }
    ]
  }, [show_chain, totalSumPNLDict, totalPNLDict])

  const handleCellClick = (params, e) => {
    if(anchorBS === null && params.column.key === 'id'){
      setOrder((o)=> ({...o, "qty": Math.abs(params.row.qty), "price": params.row.ltp, "instrument": params.row.id, "lotSize": insExpData[params.row.instrumentExp].lts ?? 1}));
      setAnchorBS(e.currentTarget)
    }
  };

  React.useEffect(()=>{
    if(socket){
      socket.on("pnlUpdate", setSData);
    }
    socket.emit('g_data', 'pnl');

    return () =>{
      if(socket){
        socket.off("pnlUpdate")
      }
    }

  }, [])

  const placeOdr = (qty) => {
    const body =  {
      "instrument": order.instrument, 
      "qty": qty, 
      "price": order.price
    }
    dispatch(placeOrder(body))
    setOrder({})
    setAnchorBS(null)
  }

  React.useEffect(()=>{
    if(socket && marktPositions.length > 0){
      socket.emit("update_preferences", {
        pref_type: "pnl",
        pnl_data: marktPositions.map((md)=> ({instrument: md.instrumentName, symbol: md.id, expiry: md.expiryStr}))
      })
    }
  }, [marktPositions, socket])

  React.useEffect(()=>{
    let interval;
    if (!document.hidden) {
      interval = setInterval(() => {
        (isDayOfWeek && moment().tz("Asia/Kolkata").isBetween(start, end)) && socket.emit('g_data', 'pnl')
      }, updateInterval); // Update count every second
      }
    
    return () => {
      interval && clearInterval(interval);
    }
  }, [document.hidden, updateInterval])
  
  React.useEffect(()=>{
    const totalExp = {};
    const totalSum = {};

    if(sData && sData["insExp"]){
      let d = {};
      Object.keys(sData["insExp"]).forEach((ie)=>{
        const expInst = sData["insExp"][ie];
        d[ie]= {"lts": expInst["lts"] ?? 1, "iv": expInst["iv"] ?? 0, "fprc": expInst["fprc"] ?? 0 }
      })
      setInsExpData({...insExpData, ...d})
    }

    const rowData = marktPositions.map((d)=>{
      const liveD = sData[d.id]
      const avgQty = d.avgQuantity ?? 0
      const avgPrice = d.avgPrice ?? 0
      const fprc = liveD && liveD.fprc ? liveD.fprc : 0
      const delta = liveD && liveD.dlt ? (fprc * avgQty * liveD.dlt)/100000 : 0
      const iv = liveD && liveD.iv ? liveD.iv : 0
      const ltp = liveD && liveD.ltp ? liveD.ltp : 0
      const prem = d.id.includes("FUT") ? 0 : avgPrice * avgQty * -1
      const rpnl = d["realizedPNL"]
      const upnl = avgQty * (ltp - avgPrice)
      const tpnl = rpnl + upnl
      const instrumentExp = d.instrumentName + d.expiryStr

      const tmp = {
        id: d.id,
        instrumentExp: instrumentExp,
        instrument: d.instrumentName,
        expiry: d.expiryStr,
        vega: liveD && liveD.vg ? parseInt(liveD.vg * avgQty) : 0,
        theta: liveD && liveD.tt ? parseInt(liveD.tt * avgQty) : 0,
        delta: delta,
        iv: iv,
        prem: parseInt(prem),
        avgPrice: avgPrice,
        qty: avgQty,
        rPNL: rpnl,
        ltp: liveD ? liveD["ltp"] : 0,
        urPNL: upnl,
        tPNL: tpnl
      }
      if(!totalExp[instrumentExp]){
        totalExp[instrumentExp] = 0
      }

      totalExp[instrumentExp] += totalExp?.instrumentExp ?? 0 + tpnl

      totalSum["vega"] = (totalSum?.vega ?? 0) + tmp.vega
      totalSum["delta"] = (totalSum?.delta ?? 0) + tmp.delta
      totalSum["prem"] = (totalSum?.prem ?? 0) + tmp.prem
      totalSum["theta"] = (totalSum?.theta ?? 0) + tmp.theta
      totalSum["urPNL"] = (totalSum?.urPNL ?? 0) + tmp.urPNL
      totalSum["rPNL"] = (totalSum?.rPNL ?? 0) + tmp.rPNL
      totalSum["tpnl"] = (totalSum?.tpnl ?? 0) + tmp.tPNL

      return(tmp)
    })
    
    setTotalPNLDict(totalExp);
    setTotalSumPNLDict(totalSum);
    setRData( sortColumn.length ? sortRows(rowData, sortColumn) : rowData);
  }, [sData, marktPositions, sortColumn, insExpData])

  const summaryRows = [
    { id: 'total'}
  ];

  return (
    rData.length ? 
      <div style={{width: '95%', padding: '1rem .5rem 0rem .5rem', margin: '0px auto', textAlign: 'center'}}>
        <TreeDataGrid style={{height: "auto"}} columns={memoizedColumns} groupBy={['instrumentExp']} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={(r) => handleSort(r, setSortColumn)} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rows={rData} rowClass={rowClass} onCellClick={handleCellClick} bottomSummaryRows={summaryRows} enableVirtualization={false} />
          
        <Popover 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorBS)}
          anchorEl={anchorBS}
          onClose={()=> setAnchorBS(null)}
        >
          {
            anchorBS ? 
            <Box display={'flex'} margin={'0.5rem'}>
              <Box display={'flex'} width={'100%'} justifyContent={"flex-end"}>
              <Button size='small' variant="outlined" sx={{color: "#22c55e", mx:"0.2rem" }} onClick={()=> placeOdr(order.qty) } >Buy</Button>
                <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} type='number' id="odrPrice" label="Price" variant="outlined" value={order.price ?? 0} onChange={handlePriceChange} inputProps={{ step:0.05, style: { textAlign: 'center' }}} />
                <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} id="odrLot" type='number' label="Qty" variant="outlined" value={order.qty ?? 0} onChange={handleQtyChange} inputProps={{ step:order.lotSize, min: 0, style: { textAlign: 'center' }}} />
                <Button size='small' variant="outlined" sx={{color: "#f72b2b", mx:"0.2rem" }} onClick={()=> placeOdr(-1*order.qty) } >Sell</Button>
              </Box>
            </Box> 
            : null
          }
        </Popover>
      </div>
    : null
  )
  
}

export default UserPosition