// src/oiSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { kiteAPI } from "../../../utils/axiosInstance";

const initialState = {
    result: {
        list: {},
        today: [],
        status: 'loading'
    }
};

export const getResultList = createAsyncThunk('events/results', async (req, {dispatch}) => {
    const today = new Date().toLocaleDateString();
    const lastUpdated = localStorage.getItem('lastUpdated');
    const dataFromStorage = localStorage.getItem('resultDataBSE');

    if (!dataFromStorage || !lastUpdated || lastUpdated < today) {
        const response = await kiteAPI.get("events/get/results");
        localStorage.setItem('lastUpdated', today);
        localStorage.setItem('resultDataBSE', JSON.stringify(response.data.success ? response.data.data :  {}));
        return response.data;
    }else if(dataFromStorage){
        dispatch(setResult(JSON.parse(dataFromStorage) ?? {} ));
        return null;
    }else{
        return null;
    }
    
});

const eventSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        setResult: (state, action) => {
            const today = new Date().toLocaleDateString();
            
            state.result.list = action.payload;
            state.result.today = Object.keys(action.payload).filter(key => new Date(action.payload[key]).toLocaleDateString() === today);
            
            state.result.status = 'success';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResultList.fulfilled, (state, action) => { 
               if(action.payload && action.payload.success){
                    const today = new Date().toLocaleDateString();

                    state.result.list = action.payload.data;
                    state.result.today = (Object.keys(state.result.list).filter(key => new Date(state.result.list[key]).toLocaleDateString() === today));
                    state.result.status = 'success';
               }
            })
            .addCase(getResultList.rejected, (state, action) => {
                const dataFromStorage = localStorage.getItem('resultDataBSE');
                if(dataFromStorage){
                    const today = new Date().toLocaleDateString();

                    state.result.status = 'success';
                    state.result.list = JSON.parse(dataFromStorage);
                    state.result.today = (Object.keys(state.result.list).filter(key => new Date(action.payload[key]).toLocaleDateString() === today));
                }else{
                    state.result.status = 'failed';
                    state.result.list = {};
                    state.result.today = [];
                }
            })
        }
    }
);

export const { setResult } = eventSlice.actions;

export default eventSlice.reducer;
