import React from 'react'
import './MarketIndices.css';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import {SocketContext}  from '../../utils/SocketContext';
import { emitDataSocket } from "../../features/slices/socket/socketSlice";
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import moment from 'moment-timezone';
import StackedLineChartSharpIcon from '@mui/icons-material/StackedLineChartSharp';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import PossibleLoss from "../Info/PossibleLoss";

const MarketIndices = () => {
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const updateInterval  = useSelector((state) => state.socket.updateInterval);
  const start  =  moment.unix(useSelector((state) => state.socket.start)).tz("Asia/Kolkata");
  const end  =  moment.unix(useSelector((state) => state.socket.end)).tz("Asia/Kolkata");
  const isDayOfWeek  = useSelector((state) => state.socket.isDayOfWeek);
  const [indices, setIndices] = React.useState([])
  const [indicesData, setIndicesData] = React.useState({})
  const [instExp, setInstExp] = React.useState({})
  const [possibleLoss, setPossibleLoss] = React.useState({})
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);

  const handleOpenExp = (instrument) => {
    setInstExp(instrument)
    dispatch(getExpList(instrument));
  };
  
  const handleCloseExp = () => {
    dispatch(clearExpList()); 
    setInstExp("");
  };

  const openChart = (instrument, expiry, change, price, iv) => {
    dispatch(updateChart({ instrument, expiry, change, price, iv }));
  }
  
  const handleExpiryChange = (exp, instrument) =>{
    const req = {
        "event": "update_preferences",
        "pref_type": "indices",
        "type": "",
        "instrument": instrument,
        "expiry": exp,
      }
    dispatch(emitDataSocket({ req, socket}))
  }
  const handleHeaderDoubleClick = (instrument, exp) =>{
        dispatch(updateChain({
        "instrument": instrument,
        "expiry": exp
    }))
  }

  // eslint-disable-next-line
  const throttledSetRows = React.useCallback(
    _.throttle((_n) => {
      const obj_keys = Object.keys(_n)
      if (obj_keys.length !== 0){
          if (indices.length !== obj_keys.length)
            setIndices(obj_keys)
              
        setIndicesData(_n)
      }
    }, 500),
    [indices]
  );

  React.useEffect(()=>{
    let interval;
    if (!document.hidden) {
      if (isDayOfWeek && moment().tz("Asia/Kolkata").isBetween(start, end)){
        interval = setInterval(() => {
          socket.emit('g_data', 'indices');
        }, updateInterval); // Update count every second
      }
    }
    
    return () => {
      interval && clearInterval(interval);
    }
  }, [document.hidden])

  React.useEffect(()=>{
    socket.on("marketIndices", throttledSetRows);
    socket.emit('g_data', 'indices');
    
    return () =>{
      if(socket){
        socket.off("marketIndices")
      }
    }
  }, [throttledSetRows])

  return (
    <Box sx={{width: '95%', padding: '1rem', margin: '0px auto', display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around' }}>
        {
        indices?.map((v)=>
          <Card key={`c_${v}`} sx={{ minWidth: 250, maxWidth: 250, margin: 2, padding:1 }}>
            <CardContent sx={{padding: 1, display:'flex', justifyContent:'center'}} >
              <Button sx={{ color:/[-]/i.test(indicesData[v]?.ch) ? '#f72b2b': '#22c55e' }} onClick={()=>handleHeaderDoubleClick(v, indicesData[v]?.ex)} variant="outlined">{`${v.toUpperCase()} (${indicesData[v]?.ch ?? ''})`}</Button>
              {/* <Typography sx={{cursor:'pointer'}}  align='center' variant="h5" component="div" 
              </Typography> */}
            </CardContent>
            <CardActions>
              <FormControl fullWidth sx={{width: '65%', margin: '0 auto'}}>
                <Select variant='standard' sx={{textAlign: 'center', padding: 0, margin: 0}} onChange={(e)=>handleExpiryChange(e.target.value, v)} open={instExp === v } onOpen={() => handleOpenExp(v)} onClose={handleCloseExp} value={indicesData[v].ex}>
                {
                    (instExp === v && expList.length ? expList : [indicesData[v].ex]).map((de, index)=>
                        <MenuItem onDoubleClick={ () => handleHeaderDoubleClick(v, de) } key={`${de}_${index}`} value={de}>{de}</MenuItem>
                    )
                }
                </Select>
              </FormControl>
            </CardActions>
            <CardContent sx={{padding: 1, color: '#c0fb13'}}>
              <Typography  title={"Implied Future, Click to Open chart !"} align='center' sx={{cursor: 'pointer'}} variant="h6" component="div" onClick={()=> openChart(v, indicesData[v].ex, indicesData[v]?.ch, indicesData[v]?.fp, indicesData[v]?.iv )}>
              {`${indicesData[v]?.fp - (indicesData[v]?.di ?? 0) }`} <StackedLineChartSharpIcon sx={{color: 'white'}} />
              </Typography>
              <Typography title={currentUser ? "IV, Click to get Possible Loss !": "IV"} sx={{cursor: 'default'}} variant="h6" alignItems={'center'} display={'flex'} justifyContent={'center'} component="div">
              {`${indicesData[v]?.iv}`} { currentUser ? <AirlineStopsIcon onClick={(e)=> setPossibleLoss({request: {portfolio: v, expiry: indicesData[v].ex}, position: e.currentTarget})} sx={{color: 'white', mx:.5}} /> : null }
              </Typography>
              <Typography  title={"PCR-OI, PCR-Vol"} align='center' sx={{cursor: 'default'}} variant="h6" component="div">
              {`${indicesData[v]?.o_r}`}, {`${indicesData[v]?.v_r}`}
              </Typography>
              <Typography  title={"Max Pain, Std Price"} align='center' sx={{cursor: 'default'}} variant="h6" component="div">
              {`${indicesData[v]?.m_p}`}, {`${indicesData[v]?.s_p}`}
              </Typography>
            </CardContent>
          </Card>
        )
        }
      <PossibleLoss possibleLossParams={possibleLoss} setPossibleLoss={setPossibleLoss}/>
    </Box>

  )
}

export default MarketIndices