import React from 'react';
import './Strategy.css';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Fab, InputLabel, MenuItem, Modal, Paper, Select, Stack, Tabs, Tab, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';
import StopIcon from '@mui/icons-material/Stop';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createAlert } from "../../../features/slices/alert/TransitionAlertsSlice";
import { getList, clearSearch } from "../../../features/slices/search/searchSlice";
import { useDispatch, useSelector } from 'react-redux';
import SelectInstrument from "../../SelectInstrument/SelectInstrument";
import PayoffChart from "../../Charts/PayoffChart";
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { kiteAPI, socketAPI, tradeUser } from "../../../utils/axiosInstance";
import {SocketContext}  from '../../../utils/SocketContext';

const def_split ={user: "", positions: {}, exposure: 10, agress: 0.05, delta_order: true}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'black',
  border: '1px solid grey',
  maxHeight: '85vh',
  overflow:'auto',
  boxShadow: 24,
};

const PositionInput = React.memo(({params, setPosition}) => {
  const [value, setValue] = React.useState(params.value);
  const onKeyDownCapture = React.useCallback((e)=>e.stopPropagation(), []);

  const handleChange = React.useCallback((value)=>{
    setValue(value);
    value && setPosition(p => { 
      const i = p.findIndex((_p) => _p.id === params.id);
      p = [...p]; 
      p[i].pos = parseInt(value); 
      p[i].qty= p[i].pos*params.row.lt; 
      return(p); 
    });
  }, [setPosition]);

  React.useEffect(() => setValue(params.value), [params.value]);

  return <TextField size="small" variant="standard" focused inputProps={{step: 1, style: { textAlign: "center" }}} type="number" placeholder="Lots" value={value} onChange={(e) => handleChange(e.target.value)} onKeyDownCapture={onKeyDownCapture} />;
}, (pre, next) => pre.params.value === next.params.value);

const Margin = React.memo(({margin, balance}) => {
  margin = margin/100000;
  const diff = balance - margin;

  return <>Margin Required:<Typography component='span' sx={{marginX:'0.2rem', color: diff >= 0 ? 'green': 'red'}}>{parseFloat(margin).toFixed(2)} lakh / {parseFloat(balance).toFixed(2)} lakh</Typography></>
}, (pre, next) => (pre.margin === next.margin && pre.balance === next.balance));

export default function Strategy({open, setOpen}){
  const dispatch = useDispatch();
  const gridRef = useGridApiRef();
  const { takePosition, balances } = React.useContext(SocketContext);
  const [instrument, setInstrument] = React.useState({ instrument: 'NIFTY', expiry: null, strategy: null, exit_time: null, vega:4 });
  const [expList, setExpList] = React.useState([]);
  const [chain, setChain] = React.useState({});
  const [totalPosition, setTotalPosition] = React.useState({value:0, theta:0, vega:0, prem:0, gamma:0});
  const [strategyList, setStrategyList] = React.useState([]);
  const [position, setPosition] = React.useState([]);
  const [listIndex, setListIndex] = React.useState({exp: undefined, str: undefined });
  const [loadingExp, setLoadingExp] = React.useState(false);
  const [deleteBool, setDeleteBool] = React.useState(false);
  const [takePositionBool, setTakePositionBool] = React.useState(false);
  const [loadingStratergy, setLoadingStratergy] = React.useState(false);
  const [fetchingStratergy, setFetchingStratergy] = React.useState(false);
  const [chainList, setChainList] = React.useState([]);
  const [strikeList, setStrikeList] = React.useState([]);
  const searchList  = useSelector((state) => state.search.instruments.list);
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const tradeUsers = useSelector((state) => state.tradeUsers.users);
  const searchFetch = useSelector((state) => state.search.instruments.status);
  const [split, setSplit] = React.useState(def_split);
  const [splitModify, setSplitModify] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showPayOff, setShowPayOff] = React.useState(true);
  const [positionMargin, setPositionMargin] = React.useState({});

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleClose = () => setOpen(false);

  const getStrategyList = React.useCallback(async ()=>{
    setLoadingStratergy(true);
    const r = await kiteAPI.get('strategy/list');
    setLoadingStratergy(false);
    if (r?.data?.success) 
      setStrategyList(r.data.data);
    else
      setStrategyList([]);
  }, []);
  
  const getStrategy = React.useCallback(async (e) => {
    e.preventDefault();
    setFetchingStratergy(true);
    const r = await kiteAPI.post('strategy/get-positions', {...instrument, exit_time: instrument?.exit_time?.toISOString()?.split('T')?.[0] ?? null });
    setFetchingStratergy(false);
    if (r?.data?.success && Array.isArray(r.data?.data)){
      if(r.data.data.length === 0){
        dispatch(createAlert({message: "No positions found, Try with different values...", type: 'error', timeout: 3000}));
        return;
      }

      const pos = [...position];
      const ffp = chain?.ffp ?? 0;
      r.data.data.forEach(inst => {
        const c = chain?.c?.[inst?.ins] ?? false; const lt = (inst?.ins?.includes('FUT') ? (chain.flt ?? 0) : (chain?.lt ?? 0)); const pI = pos.findIndex(d => d?.ins === inst?.ins)
        if (c && (pI === -1)) {
          const qty = lt * inst?.pos;
          pos.push({...c, ...inst, exp: instrument.expiry, ffp, lt, qty});
        }else{
          const _inst = pos[pI]; 
          const _pos = parseInt(_inst?.pos ?? 0) + parseInt(inst?.pos ?? 0); 
          const qty = lt * _pos;
          
          pos[pI] = {..._inst, qty, pos: _pos}
        }
      });
      setPosition(pos);
    }
  }, [instrument, chain, position]);
 
  const deleteInstruments = React.useCallback(()=>{
    const selected = gridRef.current.getSelectedRows();
    
    setPosition((p => {
      return([ ...p.filter(x => !selected.has(x.id))]);
    }))
  }, [gridRef]);

  const getExpList = React.useCallback(async (instrument)=>{
    setLoadingExp(true);
    const r = await socketAPI.get(`instruments/expiry-list/${instrument}?type=d`);
    setLoadingExp(false);
    if (r?.data?.success){
      setExpList(r.data.data);
    }
    else{
      setExpList([]);
    }
  }, [listIndex]);

  const getChainData = React.useCallback(async (instrument, expiry)=>{
    // setLoadingStratergy(true);
    const r = await socketAPI.post('/chain/get-chain', {instrument, expiry});
    // setLoadingStratergy(false);
    if (r?.data?.success){
      setChain(r.data.data ?? {});
      setStrikeList(Array.from(new Set(Object.values(r.data.data.c).map(d => d.st))));
    }
    else{
      setStrikeList([]);
      setChain({});
    }
  }, []);

  const getTakePosition = React.useCallback(async (req)=>{
    setTakePositionBool(true);
    const r = await tradeUser.post('orders/take-split-start', req);
    setTakePositionBool(false);
    // if (r?.data?.success){
    //   setPosition({});
    // }
    // else{
    //   setChain({});
    // }
  }, []);
  
  const stopTakePosition = React.useCallback(async (req)=>{
    // setTakePositionBool(true);
    const r = await tradeUser.post('orders/take-split-stop', req);
    // setTakePositionBool(false);
    // if (r?.data?.success){
    //   setChain(r.data.data ?? {});
    // }
    // else{
    //   setChain({});
    // }
  }, []);

  const changeTakePosition = React.useCallback(async (req)=>{
    // setTakePositionBool(true);
    const r = await tradeUser.post('orders/take-split-pause', req);
    // setTakePositionBool(false);
    // if (r?.data?.success){
    //   setChain(r.data.data ?? {});
    // }
    // else{
    //   setChain({});
    // }
  }, []);

  const modifyTakePosition = React.useCallback(async (req)=>{
    // setTakePositionBool(true);
    const r = await tradeUser.post('orders/take-split-modify', req);
    // setTakePositionBool(false);
    // if (r?.data?.success){
    //   setChain(r.data.data ?? {});
    // }
    // else{
    //   setChain({});
    // }
  }, []);
  
  const addPosition = React.useCallback((inst)=>{
    if(inst && (position.findIndex(d => d?.ins === inst) === -1) && chain?.c?.[inst]){
      const tmp = [...position];
      const c = chain.c[inst];
      const lt = (inst.includes('FUT') ? (chain.flt ?? 0) : (chain?.lt ?? 0));

      tmp.push({...c, ins:inst, exp: instrument.expiry, pos:1, ffp: chain?.ffp ?? 0, lt, qty: lt, v: c?.v ?? 0, g: c?.g ?? 0});
      setPosition(tmp);
    }
    }, [chain, position]);

  const filterChainInstruments = React.useCallback((s, limit=8)=>{
    const d = [];
    for ( const _e of new Set(Object.keys(chain?.c?? {})).difference(new Set(position.map(d=> d?.ins ?? "")))){
      if(d.length === limit) break;
      _e.toLowerCase().includes(s.toLowerCase()) && d.push(_e);
    }
    setChainList(d);
  }, [chain, position]);
  
  const memoizedColumns = React.useMemo(() => ([
    { 
      field: 'id', 
      headerName: 'Instrument', 
      flex: true,
      align: 'center',
      headerAlign: 'center', },
    {
      field: 'p',
      minWidth: 120,
      type: 'number',
      headerName: 'Price',
    },
    {
      field: 'v',
      minWidth: 120,
      type: 'number',
      headerName: 'Volumes',
    },
    {
      field: 'd',
      headerName: 'Exposure (in lakh)',
      minWidth: 120,
      hideSortIcons: true,
      type: 'number',
      valueGetter: (value, row) => parseFloat((row.qty * row.ffp * value)/100000 ?? 0).toFixed(2),
    },
    {
      field: 'vg',
      headerName: 'Vega',
      minWidth: 100,
      type: 'number',
      valueGetter: (value, row) => parseInt((row.qty * value) ?? 0) ?? '-',
    },
    {
      field: 't',
      minWidth: 100,
      headerName: 'Theta (in Rs.)',
      hideSortIcons: true,
      type: 'number',
      valueGetter: (value, row) => parseInt((row.qty * value) ?? 0) ?? '-',
    },
    {
      field: 'g',
      minWidth: 80,
      headerName: 'Gamma',
      type: 'number',
      valueGetter: (value, row) => parseFloat((row.qty * value) ?? 0).toFixed(3) ?? '-',
    },
    {
      field: 'margin',
      headerName: 'Margin (in lakh)',
      hideSortIcons: true,
      minWidth: 110,
      type: 'number',
      valueGetter: (_, row) => parseFloat((positionMargin?.[row.id] ?? 0)/100000).toFixed(2),
    },
    {
      field: 'qty',
      headerName: 'Qty',
      minWidth: 70,
      type: 'number',
    },
    {
      field: 'pos',
      headerName: 'Position',
      minWidth: 90,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: false,
      display: 'flex',
      filterable: false,
      renderCell: (params) => <PositionInput params={params} setPosition={setPosition}/>,
    }
  ]), [positionMargin]);
  
  React.useEffect(()=>{
    if (split.user && position.length){
      (async () => {
        const r = await tradeUser.post('info/get-margin', {user: split.user, positions: position.reduce((acc, d)=> ({...acc, [d.id]: d.qty}), {})});
        if (r?.data?.success && r.data?.margins) setPositionMargin(r.data.margins);
        else setPositionMargin({});
      })();
    }
    else setPositionMargin({});
  }, [split.user, position]);

  React.useEffect(()=>{
    if(instrument.instrument && instrument.expiry){
      getChainData(instrument.instrument, instrument.expiry);
    }else if (Object.keys(chain).length){
      setChain({});
    }
    position.length && setShowPayOff(false);
  }, [instrument.instrument, instrument.expiry]);

  React.useEffect(() => {
    const _len = expList.length
    if (_len){
      let exp;
      if (_len > listIndex.exp){
        exp = expList[listIndex.exp];
      }else{
        exp = expList[0];
        setListIndex((li) => ({...li, exp: 0 }));
      }
      setInstrument((i) => ({...i, expiry: exp?.name, exit_time: new Date(exp?.e) }));
    }else{
      setInstrument((i) => ({...i, expiry: null, exit_time: null }));
      setListIndex((li) => ({...li, exp: undefined}));
    }
  }, [expList, listIndex.exp]);

  React.useEffect(() => {
    const str = JSON.stringify(listIndex.str) ? strategyList[listIndex.str] : null;
    str && setInstrument((i) => ({...i, strategy: strategyList?.[listIndex?.str]?.key ?? null }));
  }, [strategyList, listIndex.str]);
  
  React.useEffect(() => {
    if (instrument.instrument){
      getExpList(instrument.instrument, true);
    }else{
      setExpList([]);
    }
  }, [instrument.instrument]);
  
  React.useEffect(() => {
    const t = {value:0, theta:0, vega:0, prem:0, gamma:0}
    position.forEach((inst)=>{
      t.value += (inst.qty * inst.ffp * inst.d)/100000;
      t.theta += inst.qty * inst.t;
      t.gamma += inst.qty * inst.g;
      t.vega += inst.qty * inst.vg;
      t.prem += inst.it === 'FUT' ? 0 : -(inst.qty * inst.p) ;
    });
    t.value = parseFloat(t.value).toFixed(2);
    t.vega = parseInt(t.vega);
    t.theta = parseInt(t.theta);
    t.prem = parseInt(t.prem);
    t.gamma = parseFloat(t.gamma).toFixed(3);
    setTotalPosition(t);
    !position.length && setShowPayOff(true);
  }, [position]);

  React.useEffect(() => { 
    setSplit({...split, user: currentUser});
    try {
      gridRef.current.setColumnVisibility("margin", Boolean(currentUser));
    } catch (error) {}
    
  }, [currentUser, gridRef]);

  React.useEffect(() => { Object.keys(chain).length && filterChainInstruments(''); }, [filterChainInstruments]);
  React.useEffect(() => { setSplitModify(takePosition); }, [takePosition]);
 
  return (
    <Modal open={open} keepMounted onClose={handleClose} aria-labelledby="Strategy" aria-describedby="Create Strategy...">
      <Box sx={style}>
        { fetchingStratergy ? <LinearProgress sx={{position: 'absolute', height: '0.2rem', width: '100%'}} /> : null }
        <Box component="form" onSubmit={getStrategy} sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', m: 'auto 10px auto 5px', p: '0.5rem', justifyContent: 'center' }}>
          <FormControl size='small' sx={{m: '0.1rem', width: '13.2rem'}}>
            <SelectInstrument value={instrument.instrument} width={'13rem'} label={"Underlying"} loading={searchFetch === 'loading'} list={searchList} getList={(search)=> {dispatch(getList({search, nifty: false})); }} onChange={(v) => { setInstrument({...instrument, instrument: v, expiry: !v? null: instrument.expiry }); dispatch(clearSearch()); }}/>
          </FormControl>
          <FormControl size='small' sx={{m: '0.1rem', width: '8rem'}} disabled={!instrument.instrument} >
            <InputLabel id="expiry-label">Expiry</InputLabel>
            <Select labelId="expiry-label" label={'Expiry'} value={listIndex.exp ?? ''} onOpen={()=>getExpList(instrument.instrument)} onChange={(e) => setListIndex({...listIndex, exp: e.target.value})}>
              { loadingExp ? <MenuItem key={`exp_load`} disabled value={'load'}>Loading...</MenuItem> : expList.map((d, i)=> <MenuItem key={`exp_sel_${i}`} value={i}>{d.name}</MenuItem>) }
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker disabled={!instrument.expiry} slotProps={{ textField: { size: 'small' } }} format="dd-MM-yyyy" value={instrument.exit_time} onChange={(date) => setInstrument({ ...instrument, exit_time: date})} sx={{m:'0.1rem', width: '10rem'}} label="Exit Date" maxDate={expList?.[listIndex.exp]?.e && new Date(expList[listIndex.exp]?.e)} />
          </LocalizationProvider>
          <FormControl size='small' sx={{m: '0.1rem', width: '11rem'}}>
            <InputLabel id="strategy-label">Strategy</InputLabel>
            <Select onOpen={()=>getStrategyList()} labelId="strategy-label" value={listIndex.str ?? ''} label={'Strategy'} onChange={(e) => setListIndex({...listIndex, str: e.target.value})}>
              { loadingStratergy ? <MenuItem key={`str_load`} disabled value={'load'}>Loading...</MenuItem> : strategyList.map((d, i) => <MenuItem key={`str_sel_${i}`} value={i}>{d.name}</MenuItem>) }
            </Select>
          </FormControl>
          {
            instrument.strategy?.includes('vega') ?
            <FormControl sx={{m: '0.1rem', width: '7rem'}}>
              <TextField size='small' inputProps={{step: 1, min:0, style: { textAlign: 'center' }}} type='number' label={'Target Vega'} placeholder={"Vega in thousands"} value={instrument.vega} onChange={(e)=> setInstrument({...instrument, vega: e.target.value})} />
            </FormControl>
            :null
          }
          <Button type="submit" disabled={!Object.values(instrument).every(value => value !== null) || fetchingStratergy} color="primary" variant="outlined" sx={{m: '0.1rem', width: '10rem'}}>Get Positions</Button>
        </Box>
        <Box sx={{display: 'flex', bgcolor: 'background.paper', width: '95%', margin: '0 auto',}}>
          <Box sx={{display: 'flex', width: '70%', alignItems: 'center'}}>
            {
              chain?.ffp ?
              <>
                <Typography component={'span'} marginX={2} alignItems='center' display={'flex'}>Underlying Price: {parseFloat(chain.ffp).toFixed(2)}</Typography>
                <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>IV: {chain?.i && chain?.i !== '-' ? parseFloat(chain.i).toFixed(2): '-'}</Typography>
                <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Lot: {chain?.flt ? chain.flt : '-'}</Typography>
                <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Option Lot: {chain?.lt ? chain.lt : '-'}</Typography>
              </>:null
            }
          </Box>
          <Box sx={{display: 'flex', width: '30%', alignItems: 'center'}}>
            <SelectInstrument width={'100%'} disabled={!Object.keys(chain).length} placeholder={"Select instruments!"} list={chainList} getList={filterChainInstruments} onChange={addPosition}/>
          </Box>
        </Box>
        <Box sx={{ width: '95%', position: 'relative', p:'.1rem', margin: '0 auto', height: '15rem'}}>
          <DataGrid apiRef={gridRef} rowHeight={35} getRowClassName={(p)=>`textclr-${p.row.color}`} columnHeaderHeight={30} initialState={{sorting: {sortModel: [{field: 'id', sort: 'asc'}]}, columns: {columnVisibilityModel:{"margin": false}}}} rows={position} columns={memoizedColumns} hideFooter checkboxSelection onRowSelectionModelChange={(d) => setDeleteBool(Boolean(d.length))} sx={{fontSize: '1.1rem', fontWeight: 650, '.MuiDataGrid-iconButtonContainer': { visibility: open ? 'visible': 'hidden !important'}}}/>
          {
            deleteBool ?
              <Fab sx={{position:'absolute', bottom: 15, right: 15, zIndex: 6}} color='primary' aria-label="delete-position" onClick={deleteInstruments} >
                <DeleteIcon />
              </Fab>
            :null
          }
        </Box>
        <Box sx={{ margin: '0 auto 0.5rem', width: '95%', bgcolor: 'background.paper', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap'}}>
          <Typography sx={{marginX:'0.2rem'}}>Exposure: {totalPosition.value} lakh</Typography>
          <Typography sx={{marginX:'0.2rem'}}>Vega: {totalPosition.vega}</Typography>
          <Typography sx={{marginX:'0.2rem'}}>Theta: {totalPosition.theta}</Typography>
          <Typography sx={{marginX:'0.2rem'}}>Prem: {totalPosition.prem}</Typography>
          <Typography sx={{marginX:'0.2rem'}}>Gamma: {totalPosition.gamma}</Typography>
          {split?.user ? <Typography sx={{marginX:'0.2rem'}}><Margin balance={balances?.[split.user]?.balance ?? 0} margin={positionMargin?.total_margin ?? 0} /></Typography>:null}
        </Box>
        {
          showPayOff ?
            <Box sx={{ width: '90%', position: 'relative', p:'.1rem', margin: '0 auto'}}>
              <PayoffChart position={position} fut_price={chain?.ffp ?? 0} strikeList={strikeList} />
            </Box>
          : null
        }
        <Paper sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex:7 }} elevation={1}>
          <Box sx={{justifyContent: 'flex-end', display: 'flex', flexWrap: 'wrap'}}>
            {
              currentUser ?
              <Stack sx={{m: '0.4rem 0.2rem 0.1rem 0'}} direction="row" spacing={1}>
                <Tabs sx={{ minHeight: "2.5rem", height: "2.5rem" }} value={split.user} onChange={(e,v) => setSplit({...split, user: v})} textColor={splitModify?.[split.user] ? 'secondary': 'primary'} indicatorColor={splitModify?.[split.user] ? 'secondary': 'primary'} >
                  { tradeUsers?.map((t) => <Tab sx={{minHeight: "2.5rem", height: "2.5rem", p:0, color: splitModify?.[t] ? 'yellow': ''}} value={t} label={t} />) }
                </Tabs>
                {
                  splitModify?.[split.user] ? 
                  <>
                    <TextField size='small' sx={{width: '6rem'}} type='number' label="Exposure" title="Exposure" variant="outlined" value={splitModify[split.user]?.exposure ?? 0} onChange={(e) => setSplitModify({...splitModify, [split.user]: {...splitModify[split.user], exposure: parseInt(e.currentTarget.value)}})} inputProps={{min: 0, style: { textAlign: 'center' }}} />
                    <TextField size='small' sx={{width: '6rem'}} type='number' label="Agress" title="Agress" variant="outlined" value={splitModify[split.user]?.agress ?? 0} onChange={(e) => setSplitModify({...splitModify, [split.user]: {...splitModify[split.user], agress: parseFloat(e.currentTarget.value)}})} inputProps={{step: 0.05, style: { textAlign: 'center' }}} />
                    <Checkbox title='Delta Order' checked={splitModify[split.user]?.delta_order} onChange={() => setSplitModify({...splitModify, [split.user]: {...splitModify[split.user], delta_order:!splitModify[split.user].delta_order }})} />
                    <Button variant='outlined' color="warning" title='Modify' onClick={()=> modifyTakePosition({user: split.user, exposure: splitModify[split.user].exposure, agress: splitModify[split.user].agress, delta_order: splitModify[split.user].delta_order})}>
                      <EditIcon />
                    </Button>
                    <Button variant='outlined' color="primary" title={splitModify[split.user]?.pause ? 'Resume' : 'Pause'} onClick={()=> changeTakePosition({user: split.user, pause: !splitModify[split.user]?.pause })}>
                      {splitModify[split.user]?.pause ? <PlayArrowIcon /> : <PauseIcon />}
                    </Button>
                    <Button variant='outlined' color="error" title='Stop' onClick={()=> stopTakePosition({user: split.user})}>
                      <StopIcon />
                    </Button>
                  </>
                  :
                  <>
                    <TextField size='small' sx={{width: '6rem'}} type='number' label="Exposure" title="Exposure" variant="outlined" value={split.exposure} onChange={(e) => setSplit({...split, exposure: parseInt(e.currentTarget.value)})} inputProps={{min: 0, style: { textAlign: 'center' }}} />
                    <TextField size='small' sx={{width: '6rem'}} type='number' label="Agress" title="Agress" variant="outlined" value={split.agress} onChange={(e) => setSplit({...split, agress: parseFloat(e.currentTarget.value)})} inputProps={{step: 0.05, style: { textAlign: 'center' }}} />
                    <Checkbox title='Delta Order' checked={split.delta_order} onChange={() => setSplit({...split, delta_order: !split.delta_order})} />
                    <Button variant='outlined' color="primary" title='Take Position' disabled={!position.length || takePositionBool} onClick={handleClickOpenDialog}>
                      <PlayArrowIcon />
                    </Button>
                  </>
                }
              </Stack>
              : <Button size='small' sx={{m: '0.4rem 0.2rem 0.1rem 0'}} type="submit" disabled={!position.length} variant="outlined" color="secondary">Take Positions</Button>
            }
          </Box>
          { takePositionBool ? <LinearProgress sx={{position: 'absolute', height: '0.2rem', width: '100%'}} /> : null }
        </Paper>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            <Typography component={'span'} color={'secondary'} sx={{marginX: '0.2rem'}}>{split.user.toUpperCase()}</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Exposure: {split.exposure}</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Agress: {split.agress}</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Delta Order: {split.delta_order ? 'Yes': 'No'}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Exposure: {totalPosition.value} lakh</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Vega: {totalPosition.vega}</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Theta: {totalPosition.theta}</Typography>
            <Typography component={'span'} sx={{marginX: '0.2rem'}}>Premium: {totalPosition.prem}</Typography>
            <Typography component={'span'} sx={{marginX:'0.2rem'}}>Gamma: {totalPosition.gamma}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog}>Cancel</Button>
            <Button color={'secondary'} onClick={() => {getTakePosition({...split, positions: position.reduce((_ac, _i) => ({..._ac, [_i.id]: _i.qty}), {}) }); handleCloseDialog();}} autoFocus>Sure</Button>
          </DialogActions>
        </Dialog>
      </Box>

    </Modal>
  )
}