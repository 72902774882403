import moment from "moment";

export const isValidDate =  (dateString) => {
    // Use moment.js to parse the date string
    const date = moment(dateString, [ 'YYYY-MM-DD', 'YYYY-MMM-DD', 'DD-MM-YYYY', 'MM/DD/YYYY', 'MMMM D, YYYY', 'MMM D, YYYY', 'DD-MMM-YYYY', 'MMM/DD/YYYY', 'D-MMM-YYYY',  'DMMMYYYY',  'DMMMYY', 'YY-MM-DD', 'YY-MMM-DD', 'DD-MM-YY', 'MM/DD/YY', 'MMMM D, YY', 'MMM D, YY', 'DD-MMM-YY', 'MMM/DD/YY', 'D-MMM-YY',  'DMMMYY',], true);
    return date;
}

export const handleSpeak = (text, rate=0.7) => {
  if ("speechSynthesis" in window) {
      const voices = window.speechSynthesis.getVoices();
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.rate = rate;
      utterance.voice = voices[3];
      utterance.lang = voices[3].lang;
      console.log(utterance);
      
      window.speechSynthesis.speak(utterance)
    } else {
      console.error("Your browser does not support text-to-speech!");
    }
};

export function splitDateTime(dateTime) {
  // Create a JavaScript Date object from the input string
  const date = new Date(dateTime);

  // Extract the date in "YYYY-MM-DD" format
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${yyyy}-${mm}-${dd}`;

  // Extract the time and format it as "hh:mm A"
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format, 0 becomes 12
  const formattedTime = `${String(hours).padStart(2, "0")}:${minutes} ${period}`;

  return { formattedDate, formattedTime };
}

export const calculatePayoff = (positionType, currentPrice, quantity, strikePrice = 0, price = 0) => {
  let payOff = 0
  // Calculate payoff based on position type, current price, strike price, quantity, and price (if applicable)
  if (positionType === 'XX')
    payOff = (currentPrice - strikePrice) * quantity;
  else if (positionType === 'CE')
    payOff = (Math.max( (currentPrice - strikePrice), 0) - price) * quantity;
  else if (positionType === 'PE')
    payOff = (Math.max((strikePrice - currentPrice), 0) - price) * quantity;

  return payOff;
}