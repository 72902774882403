import React from 'react'
import './TradeUserPosition.css';
import { Box, Button, TextField, IconButton, Typography, Popover, Checkbox } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {SocketContext}  from '../../utils/SocketContext';
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import { placeOrderMarket, splitModify, stopSplit, startSplit, getWhatIf, clearWhatIf } from "../../features/slices/trade_user/tradeUserSlice";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import { sortRows, sum, rowGrouper } from "./tableFunctions";

const def_split ={user: "", portfolio: "", exposure: 5, agres: 0.05, type: "", delta_order: true}
const groupsOpt = [["u","g_k"], ["g_k","stk"]];
const isFutOrOption = (str) => /CE|PE|FUT/.test(str ?? '');

const rowClass = (n, key="pl") => {
  if (n?.stk) return (n.stk?.includes("CE") ? "textclr-green" : n.stk?.includes("PE") ?  "textclr-red" : "textclr-yellow");
  else if (n?.pl) return (n.pl > 0 ? "textclr-green" : n < 0 ?  "textclr-red" : "textclr-yellow");
  else if (Array.isArray(n))n = sum(n, key);
  return (n > 0 ? "textclr-green" : n < 0 ?  "textclr-red" : "textclr-yellow")
}

const placeOdrMarket = (qty, selectedRows, setSelectedRows, dispatch, orderAgress) => {
  const body = {
    "instruments": Array.from(selectedRows), 
    "qty": qty, 
    "agress": parseFloat(orderAgress)
  }
  dispatch(placeOrderMarket(body))
  setSelectedRows(new Set())
}

const handleSort = (sortColumns, setSortColumn) => {
  setSortColumn([...sortColumns]);
};

const trade_portfolio_index = (localStorage.getItem('trade_portfolio_index')) ? Number(localStorage.getItem('trade_portfolio_index')) : 1;

const TradeUserPosition = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { balances, splitout_data, tradeMarktPositions } = React.useContext(SocketContext);
  const [groupIndexOpt, setGroupIndexOpt] = React.useState(trade_portfolio_index);
  const [group, setGroup] = React.useState(groupsOpt[groupIndexOpt]);
  const whatIf = useSelector((state) => state.tradeUsers.whatIf);
  const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set());
  const [selectedRows, setSelectedRows] = React.useState(new Set());
  const [hovType, setHovType] = React.useState("");
  const [rData, setRData] = React.useState([]);
  const [sortColumn, setSortColumn] = React.useState([]);
  const [orderLot, setOrderLot] = React.useState(1);
  const [orderAgress, setOrderAgress] = React.useState(0.05);
  const [anchorBS, setAnchorBS] = React.useState(null);
  const [split, setSplit] = React.useState(def_split);
  const [hide, setHide] = React.useState(localStorage.getItem('hide_settled') ?? false);

  const getWhatIfData = React.useCallback((portfolio, expiry, user, position) => {
    dispatch(getWhatIf({user, portfolio, expiry }));
    setHovType('whatIf');
    setAnchorEl(position);
  }, []);

  const show_chain = React.useCallback((instrument, expiry) => {
    dispatch(updateChain({
      "instrument": instrument,
      "expiry": expiry
    }))
  }, [])

  const openPayOff = React.useCallback((arr, fut)=>{
    const ffp = fut.fp ?? 0;
    const position = arr.map((row) => {
      let st, it = 0;
      if (row.stk === "FUT") {
        st = ffp;
        it = row.stk;
      }else{
        const match = row.stk.match(/(\d+)([A-Za-z]+)/);
        if (match){
          st = Number(match[1]) ?? 0;
          it = match[2];
        }else{
          st = 0;
          it = "";
        }
      }
      return({it, st, qty: row.qt, ffp, p: row.fp });
    })
    
    // const position = row.childRows.map(d=> ({it, st,  qty: 100, ffp, p: d.fp }))
    dispatch(updateChart({ chartType: "payOff", price: ffp, expiry: arr[0].exp, instrument: arr[0].nm, position}));
  }, [dispatch]);

  const handleSplit = React.useCallback(async (type=null, portfolio=null, user=null) => {
    const Body = {
      portfolio: portfolio ?? split.portfolio,
      user: user ?? split.user,
      agress: split.agres,
      exposure: split.exposure,
      delta_order: split.delta_order
    };
    switch(type ?? split.type) {
      case "stop":
        dispatch(stopSplit(Body));
        break;
      case "start":
        dispatch(startSplit(Body));
        break;
      case "modify":
        dispatch(splitModify(Body));
        break;
      default:
        break
    }
    setAnchorBS(null); 
    setSplit(def_split);

  }, [split]);

  const GroupRowCell = React.useCallback(({ id, row }) => {
    const fut = tradeMarktPositions?.fut?.[row?.groupKey] ?? {};
    if(fut?.[id])
      return (<span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{ fut[id] ?? 0 }</span>)
    return null
  } , [tradeMarktPositions.fut])

  const GroupRow = React.useCallback((row) => {
    const fut = tradeMarktPositions?.fut ?? {};
    if (row.column.key === "u" && group[0] === "u")
      return <div className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)} style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: '0.9rem'}}>{row.groupKey}{row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
    else if(row.column.key === "g_k"){
      const r = row.childRows[0]
      return <div onDoubleClick={()=>{show_chain(r.nm, r.exp)}} className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)} style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: '0.9rem'}}>{row.groupKey}{row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
    }
    else if(row.column.key === "stk"){
      if (balances?.[row.groupKey]){
        return <span>Bal:<span style={{color: balances[row.groupKey].balance > 0 ? "#22c55e" : "#f72b2b", }}>{balances[row.groupKey].balance}</span></span>
      }
      else if(fut[row.row.groupKey]){
        
        if (splitout_data?.[row.row.parentId]?.[row.row.groupKey]){
          return(
            <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <IconButton aria-label="edit" color="warning" onClick={(e)=> { setAnchorBS(e.currentTarget); setSplit({...split, user: row.row.parentId, portfolio: row.row.groupKey, type: "modify", exposure: splitout_data[row.row.parentId][row.row.groupKey].exposure, agres: splitout_data[row.row.parentId][row.row.groupKey].agress, delta_order: splitout_data[row.row.parentId][row.row.groupKey].delta_order }) }}>
                <ModeOutlinedIcon />
              </IconButton>
              <IconButton aria-label="edit" color="secondary" onClick={()=> {handleSplit("stop", row.row.groupKey, row.row.parentId);}}>
                <StopOutlinedIcon />
              </IconButton>
            </Box>
          );
        }else{
          return(
          <IconButton aria-label="edit" color="primary" onClick={(e)=> { setAnchorBS(e.currentTarget); setSplit({...split, user: row.row.parentId, portfolio: row.row.groupKey, type: "start"}) }}>
            <PlayArrowOutlinedIcon /> 
          </IconButton>
          );
        }
      }
      else{
        return <div className={rowClass({stk: row.groupKey})} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
      }
    }
    else if(row.column.key === "tt"){
      const click = group.indexOf('g_k') === row.row.level;
      const tt = sum(row.childRows, "tt")
      const clickEvnt = (e) => { click && openPayOff(row.childRows, fut[row.row.groupKey])}
      return <div style={{height: '100%', width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center', alignContent: 'center'}}><Typography component={'span'} title={click ? 'Double click to open Pay-Off chart !' : ''} onDoubleClick={clickEvnt} className={rowClass(tt)} >{parseInt(tt)}</Typography>{ click && <AlignVerticalCenterIcon sx={{color: 'white'}} onClick={clickEvnt} />}</div>
    }
    else if(row.column.key === "pl" ){
      const click = group.indexOf('g_k') === row.row.level;
      const pl = sum(row.childRows, "pl")
      const clickEvnt = (e) => { click && getWhatIfData(row.childRows[0].nm, row.childRows[0].exp, group.includes('u') ? row.childRows[0].u : "", e.currentTarget);}
      return <div style={{height: '100%', width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center', alignContent: 'center'}}><Typography component={'span'} title={click ? 'Double click to open whatIF !' : ''} onDoubleClick={clickEvnt} className={rowClass(pl)} >{parseFloat(pl).toFixed(2)}</Typography>{ click && <AirlineStopsIcon sx={{color: 'white'}} onClick={clickEvnt} />}</div>
    }
    return null
  } , [tradeMarktPositions.fut, balances, group, splitout_data, split])

  const GroupSelect = React.useCallback(() => {
    const groupChange = () => {
      const nexIndex = (groupsOpt.length -1) === groupIndexOpt ? 0 : groupIndexOpt + 1
      setGroup(groupsOpt[nexIndex]);
      setGroupIndexOpt(nexIndex);
      localStorage.setItem('trade_portfolio_index', nexIndex)
    };

    return(<Button onClick={groupChange} sx={{position: 'absolute', zIndex: 1, left: 0, height: '2.3rem', marginLeft: 1}} size='small'><CompareArrowsIcon/></Button>)
  } , [groupIndexOpt])

  const onCellClick = React.useCallback((r, check=true) => {
    if (check && r.column.key === "select-row"){
      return;  // Do nothing when clicking on select-row column
    }
    const id = r.row.id
    const tmp = selectedRows;
    if (tmp.has(id)) {
      tmp.delete(id)
      setSelectedRows(new Set(tmp))
    }else{
      tmp.add(id)
      setSelectedRows(new Set(tmp))
    }
  }, [selectedRows]);
  
  const m_columns = React.useMemo(() => {
    const tt = tradeMarktPositions?.tt ?? {};
    const rc_pnl = rowClass(tt?.pl ?? 0);
    return [
      {
        key: "select-row",
        name: "",
        width: 45,
        minWidth: 45,
        maxWidth: 45,
        frozen: true,
        renderCell: (r) => <Checkbox sx={{p:0}} checked={selectedRows.has(r.row.id)} onClick={()=>onCellClick(r, false)}/>,
        renderGroupCell: (r) => {
          const colIds = r.childRows.map(row => row.id);
          const check = (selectedRows.intersection(new Set(colIds)));
          const indeterminate = check.size && check.size < colIds.length;
          const checked = check.size && check.size === colIds.length;
          return <Checkbox sx={{p:0}} indeterminate={Boolean(indeterminate)} checked={Boolean(checked)} onClick={() => indeterminate ? check.forEach(c => onCellClick({row: {id: c}}, false)) : check ? colIds.forEach(c => onCellClick({row: {id: c}}, false)) : colIds.forEach(c => onCellClick({row: {id: c}}, false)) }/>
        },
        renderHeaderCell: (r) => {
          const colIds = rData.map(row => row.id);
          const check = (selectedRows.intersection(new Set(colIds)));
          const indeterminate = check.size && check.size < colIds.length;
          const checked = check.size && check.size === colIds.length;
          const click = () => indeterminate ? check.forEach(c => onCellClick({row: {id: c}}, false)) : check ? colIds.forEach(c => onCellClick({row: {id: c}}, false)) : colIds.forEach(c => onCellClick({row: {id: c}}, false));
          return <Checkbox sx={{p:0}} indeterminate={Boolean(indeterminate)} checked={Boolean(checked)} onClick={click}/>
        },
        renderSummaryCell: () => selectedRows.size
      },
      {
        key: "u",
        name: "Acc",
        resizable: true,
        sortable: true,
        minWidth: 50,
        renderGroupCell: GroupRow,
        type: "string",
      },
      {
        key: "g_k",
        name: "InstrumentExp",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "string",
        renderCell: ({row})=> <Button size="small" onClick={()=>{show_chain(row.nm, row.exp)}}>{row.g_k}</Button>,
        renderGroupCell: GroupRow,
        // renderSummaryCell: () => 'Total PNL'
      },
      {
        key: "stk",
        name: "Strike",
        resizable: true,
        sortable: true,
        minWidth: 100,
        renderGroupCell: GroupRow,
        cellClass: "insExp",
        type: "string",
      },
      {
        key: "lts",
        name: "Qty",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{sum(row.childRows, "lts")}</span>,
        minWidth: 40,
        type: "number"
      },
      {
        key: "fp",
        name: "LTP",
        resizable: true,
        sortable: true,
        minWidth: 110,
        renderGroupCell: (row)=> balances[row.groupKey]?<span>Mar: <span style={{color: 'yellow'}}>{balances[row.groupKey].margins ?? 0}</span> </span>: <GroupRowCell id={'fp'} row={row} />,
        type: "number"
      },
      {
        key: "pl",
        name: "TotalPNL",
        resizable: true,
        sortable: true,
        renderGroupCell: GroupRow,
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.pl ?? 0).toFixed(2)}</span>,
        minWidth: 100,
        type: "number"
      },
      {
        key: "dt",
        name: "Exposure",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{parseFloat(sum(row.childRows, "dt")).toFixed(2)}</span>,
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.dt ?? 0).toFixed(2)}</span>,
        minWidth: 80,
        type: "number"
      },
      {
        key: "vg",
        name: "Vega",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{sum(row.childRows, "vg")}</span>, 
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.vg ?? 0)}</span>,
        minWidth: 50,
        type: "number"
      },
      {
        key: "prm",
        name: "Premium",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{sum(row.childRows, "prm")}</span>, 
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.prm ?? 0)}</span>,
        minWidth: 75,
        type: "number"
      },
      {
        key: "tt",
        name: "Theta",
        resizable: true,
        sortable: true,
        minWidth: 60,
        renderGroupCell: GroupRow,
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.tt ?? 0)}</span>,
        type: "number"
      },
      {
        key: "iv",
        name: "IV",
        resizable: true,
        sortable: true,
        minWidth: 50,
        renderGroupCell: (row)=> (<GroupRowCell id={'iv'} row={row} />),
        type: "number"
      },
      {
        key: "cst",
        name: "Cost",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(isFutOrOption(row.groupKey) ? {stk: row.groupKey} : row.childRows)}>{parseFloat(sum(row.childRows, "cst")).toFixed(2)}</span>, 
        renderSummaryCell: () => <span className={rc_pnl}>{parseFloat(tt?.cst ?? 0).toFixed(2)}</span>,
        minWidth: 65,
        type: "number"
      }
    ]
  }, [tradeMarktPositions.tt, selectedRows, rData, GroupRow])

  React.useEffect(()=>{
    const rowData = hide ? tradeMarktPositions?.inst?.filter(d=> d.lts) ?? [] : tradeMarktPositions?.inst ?? []
    setRData(sortColumn.length ? sortRows(rowData, sortColumn) : rowData);
  }, [tradeMarktPositions.inst, sortColumn, hide])

  const summaryRows = [
    { id: 'total'}
  ];

  return (
    rData.length ? 
      <div style={{width: '95%', padding: '1rem .5rem 0rem .5rem', margin: '0px auto', textAlign: 'center', position: 'relative'}}>
        <GroupSelect />
        <Checkbox title='Hide settled position !' sx={{position: 'absolute', zIndex: 1, right: 0, height: '2.3rem', marginLeft: 1}} checked={Boolean(hide)} onClick={()=>{ localStorage.setItem('hide_settled', !hide); setHide(!hide); }} />
        <TreeDataGrid style={{height: "auto", fontSize: '1rem'}} columns={m_columns} groupBy={group} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={(r) => handleSort(r, setSortColumn)} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rowKeyGetter={(row) => row.id} selectedRows={selectedRows} onSelectedRowsChange={setSelectedRows} onCellClick={onCellClick} rows={rData} rowClass={rowClass} bottomSummaryRows={summaryRows}/>
        {
          selectedRows.size ?
          <Box width={'100%'} marginTop={"0.5rem"}>
            <Box display={'flex'} width={'100%'} justifyContent={"center"}>
              <Button variant="outlined" sx={{color: "#22c55e", mx:"0.5rem" }} onClick={()=> placeOdrMarket(1*orderLot, selectedRows, setSelectedRows, dispatch, orderAgress) } >Buy</Button>
              <TextField size='small' sx={{mx: '0.5rem'}} id="odrLot" type='number' label="Lots" variant="outlined" value={orderLot} onChange={(e)=> setOrderLot(e.target.value) } inputProps={{min:0}} />
              <TextField size='small' sx={{mx: '0.5rem'}} inputProps={{step: 0.05 }} type='number' id="odrAgress" label={"Agress"} variant="outlined" value={orderAgress} onChange={(e) => setOrderAgress(e.target.value)} />
              <Button variant="outlined" sx={{color: "#f72b2b", mx:"0.5rem" }} onClick={()=> placeOdrMarket(-1*orderLot, selectedRows, setSelectedRows, dispatch, orderAgress)} >Sell</Button>
            </Box>
          </Box>
          : null
        }
        <Popover 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorBS)}
          anchorEl={anchorBS}
          onClose={()=> { setAnchorBS(null); setSplit(def_split); }}
        >
          {
            anchorBS ? 
            <Box>
              <Typography textAlign={'center'} marginBottom={'.3rem'} color={'error'}>{split.portfolio}</Typography>
              <Box display={'flex'} width={'100%'} justifyContent={"flex-end"}>
              <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} type='number' id="expo" label="Exposure" variant="outlined" defaultValue={split.type === "modify" ? splitout_data?.[split.user]?.[split.portfolio].exposure : split.exposure} value={split.exposure} onChange={(e) => setSplit({...split, exposure: parseInt(e.currentTarget.value)})} inputProps={{min: 0, style: { textAlign: 'center' }}} />
              <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} id="agress" type='number' label="Agress" variant="outlined" value={split.agres} onChange={(e) => setSplit({...split, agres: parseFloat(e.currentTarget.value)})} inputProps={{step: 0.05, style: { textAlign: 'center' }}} />
              <Checkbox sx={{mx: '0.2rem'}} id="delta_order" title='Delta Order' checked={split.delta_order} onChange={() => setSplit({...split, delta_order: !split.delta_order})} />
              <Button size='small' color='secondary' variant="outlined" sx={{mx:"0.2rem" }} onClick={() => handleSplit()} >{split.type === "modify" ? "Modify" : "Start"}</Button>
              </Box>
            </Box> 
            : null
          }
        </Popover>

        <Popover 
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={()=>{setAnchorEl(null); (hovType === 'whatIf' && dispatch(clearWhatIf())); setHovType("");}}
        >
          {
            hovType === 'whatIf' ?
            <Box sx={{background: 'black',  padding:'1rem'}}>
              {
                whatIf?.negRange ?
                <>
                  <Typography sx={{margin: 0, color: '#f72b2b'}}>{whatIf?.negRange?.toString()}</Typography>
                  <Typography sx={{margin: 0, color: '#22c55e'}}>{whatIf?.posRange?.toString()}</Typography>
                  <Typography sx={{margin: 0, color: '#c0fb13'}}>{parseFloat(whatIf?.avg).toFixed(2)}</Typography>
                </>
                : <Typography sx={{margin: 0, color: '#fff'}}>Loading...</Typography>
              }
            </Box>
            : null
          }
        </Popover>
      </div>
    : null
  )
  
}

export default TradeUserPosition;