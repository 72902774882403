import moment from 'moment';

const dateComparator = (v1, v2, cellParams1, cellParams2, dateFormat = 'DD MMM YYYY') => {
    // Handle undefined or null values
    if (!v1) return !v2 ? 0 : -1;
    if (!v2) return 1;

    // Parse the dates using moment
    const date1 = moment(v1, dateFormat, true); // Add `true` for strict parsing
    const date2 = moment(v2, dateFormat, true);

    // Handle invalid dates
    if (!date1.isValid() || !date2.isValid()) {
        console.warn('Invalid date format detected:', v1, v2);
        return 0; // Treat invalid dates as equal
    }

    // Compare dates
    if (date1.isBefore(date2)) {
        return -1;
    } else if (date1.isAfter(date2)) {
        return 1;
    }

    return 0; // Dates are equal
};

export const getSortComparator = (order = 'asc') => {
    return (v1, v2, cellParams1, cellParams2) =>
        order === 'asc' ? dateComparator(v1, v2, cellParams1, cellParams2) : -dateComparator(v1, v2, cellParams1, cellParams2);
};

