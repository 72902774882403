import React from 'react';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { kiteAPI } from "../../../utils/axiosInstance";
import { createAlert, set_stocks_movement } from "../../../features/slices/alert/TransitionAlertsSlice";
import { useDispatch, useSelector } from 'react-redux';
import { splitDateTime } from'../../../utils/globalFunctions';

export default function StocksAlert(){
  const dispatch = useDispatch();
  const _stocks_movement = useSelector((state) => state.notifications.stocks_movement);
  const [rows, setRows] = React.useState([]);
  const [init , setInit] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());

  const getAlert = React.useCallback(async()=>{
    try {
      setLoading(true);
      const res = await kiteAPI.get(`alert/get?date=${startDate.toLocaleDateString()}`);
      if (res.status === 200){
        if(res.data?.success){
          if(new Date().toLocaleDateString() === startDate.toLocaleDateString()){
            setInit(false);
            const _d = res.data.data?.reduce(
              (obj, item) => {
                const dateTime=splitDateTime(item.date_time);
                return Object.assign(obj, { [item.id]: {...item, time: dateTime.formattedTime} })
              }, {})
            dispatch(set_stocks_movement(_d));
          }else{
            setRows(res.data.data?.map((d)=> {const dateTime=splitDateTime(d.date_time); return({...d, time: dateTime.formattedTime}) }));
          }
          setLoading(false);
        }else{
          setRows([]);
          dispatch(createAlert({message: res.data.message, type:'error', timeout: 2000}))
          setLoading(false);
        }
      }
    } catch (error) {
      setRows([]);
      setLoading(false);
      dispatch(createAlert({message: "Internal error occured: Wait for a while and try restarting app...", type:'error', timeout: 2000}))
      console.error(error);
    }// eslint-disable-next-line
  }, [startDate, dispatch])

  React.useEffect(()=>{
    if ((init && new Date().toLocaleDateString() === startDate.toLocaleDateString() && Object.keys(_stocks_movement).length === 0) || new Date().toLocaleDateString() !== startDate.toLocaleDateString()){
      getAlert()
    }// eslint-disable-next-line
  }, [getAlert])

  return (
    <>
      <Box padding='.8rem' width='100%' display='flex' justifyContent='flex-end' alignItems='center' flexDirection='row'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker slotProps={{ textField: { size: 'small' } }} format="dd-MM-yyyy" onChange={(date) => setStartDate(date)} sx={{paddingX:.5}} portalId="root-portal" startDate={startDate} autoComplete='false' label="Start Date" value={startDate} />
        </LocalizationProvider>
      </Box>

      <Box width='90vw' height={'90vh'} margin='0 auto'>
        {
          (new Date().toLocaleDateString() !== startDate.toLocaleDateString() ? rows : Object.values(_stocks_movement)).sort((a,b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))?.map((d, id)=>
            <Typography key={id} fontWeight={700} p={'0.5rem'} color={'#C9DF8A'} component={'p'}>
              <Typography component={'span'} color={'secondary'}>{d.time}</Typography> <Typography component={'span'} style={{ whiteSpace: "pre-line", wordWrap: "break-word"}} >{d?.remark || "-"}</Typography>
            </Typography>
          )
        }
      </Box>
    </>
  )
}           