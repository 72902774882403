import React from 'react'
import './LiveData.css';
import { useDispatch, useSelector  } from 'react-redux';
import { Fab, Box, Menu, MenuItem, Typography } from '@mui/material';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LiveDataTable from '../../Tables/LiveDataTable';
import { UserDataTable} from '../../Tables/CustomLiveDataTable';
import MarketIndices from '../../Tables/MarketIndices';
import UserPosition from '../../Tables/UserPosition';
import { emitDataSocket } from "../../../features/slices/socket/socketSlice";
import { addNotification, addDialogAlert, set_stocks_movement } from "../../../features/slices/alert/TransitionAlertsSlice";
import { getResultList } from "../../../features/slices/events/eventSlice";
import SideBar from './SideBar';
import {SocketContext}  from '../../../utils/SocketContext';
import {socketAPI, kiteAPI} from '../../../utils/axiosInstance';
import TradeUserPosition from '../../Tables/TradeUserPosition';
import { splitDateTime } from'../../../utils/globalFunctions';

const info_local = require('../../../jsons/tables/info_ui.json');
const sell_local = require('../../../jsons/tables/sell_ui.json');
const buy_local = require('../../../jsons/tables/buy_ui.json');

const local_visiblity = localStorage.getItem('tableVisiblityPref')
const tableVisiblityPref = local_visiblity ? {...info_local.columnVisibilityModel, ...JSON.parse(local_visiblity)} : info_local.columnVisibilityModel

const liveDataColumnVisiblityShow = {}
info_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})
sell_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})
buy_local?.column.forEach((v)=>{
  if(!tableVisiblityPref.hasOwnProperty(v.key)){
    tableVisiblityPref[v.key] = true
  }
  if(v.hidable){
    liveDataColumnVisiblityShow[v.header] = v.key
  }
})

const initLiveTables = [
  {
    id: 'show_buy',
    name: buy_local.heading,
    color: '#22c55e',
    pref: buy_local,
    show: localStorage.getItem('show_buy') ? JSON.parse(localStorage.getItem('show_buy')) : true
  },
  {
    id: 'show_sell',
    name: sell_local.heading,
    pref: sell_local,
    color: "#f72b2b",
    show: localStorage.getItem('show_sell') ? JSON.parse(localStorage.getItem('show_sell')) : true
  },
  {
    id: 'show_info',
    name: info_local.heading,
    pref: info_local,
    color: "#f472b6",
    show: localStorage.getItem('show_info') ? JSON.parse(localStorage.getItem('show_info')) : true
  },

]

const LiveData = () => {
  const dispatch = useDispatch();
  const { socket, connectSocket, disconnectSocket } = React.useContext(SocketContext);
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const [expiryOptions, setExpiryOptions] = React.useState([]);
  const [instrumentExpiry, setInstrumentExpiry] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deleteOptions, setDeleteOptions] = React.useState({});
  const [anchorExpiry, setAnchorExpiry] = React.useState(null);
  const [liveDataTables, setLiveDataTables] = React.useState(initLiveTables)
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(tableVisiblityPref);

  React.useEffect(()=>{

    const list = [];

    selectedItems.map(async (i) =>{
      if(instrumentExpiry[i]){
        instrumentExpiry[i].forEach((e)=>{
          if(!list.includes(e)){
            list.push(e);
          }
        })
      }else{
        const resp = await socketAPI.get(`instruments/expiry-list/${i}`);
        if (resp.data && resp.data.success && resp.data.data){
          setInstrumentExpiry((d)=> ({...d, [i]: resp.data.data }))
          resp.data.data.forEach((e)=>{
            if(!list.includes(e)){
              list.push(e);
            }
          })
        }
      }
    })
    setExpiryOptions(list);

  },[selectedItems, instrumentExpiry])

  const remove_instrument = (type, instrument) => {
    const req = {
      "event": "update_preferences",
      "pref_type": "wait_list",
      "type": type,
      "instrument": instrument,
      "expiry": ""
    }
    dispatch(emitDataSocket({ req, socket}))
  }

  const update_stocks_movement = async () =>{
    const res = await kiteAPI.get(`alert/get?date=${new Date().toLocaleDateString()}`);
    if (res.status === 200){
      if(res.data?.success){
        const _d = res.data.data?.reduce(
          (obj, item) => {
            const dateTime=splitDateTime(item.date_time);
            return Object.assign(obj, { [item.id]: {...item, time: dateTime.formattedTime} })
          }, {})
        dispatch(set_stocks_movement(_d));
      }
    }
  }

  React.useEffect(()=>{
    connectSocket();
    update_stocks_movement()
    socket.on("session", (res)=>{
      if(res && res.reload){
        dispatch(addDialogAlert({message:"New tab opened !", buttons: [{name: 'Use here', actionType: 'SESSION_RESTORE' }] }));
      }
    })
    socket.on("_stocks_movement", (res)=>{
      if(res?.remark){
        const dateTime=splitDateTime(res.date_time);
        dispatch(set_stocks_movement({[res.id]: {...res, time: dateTime.formattedTime}}));
        // dispatch(createAlert({message: res.remark, type:'success', timeout: 2000}));
      }
    })
    return () => {
      socket.off("session");
      socket.off("_stocks_movement");
      disconnectSocket();
      dispatch(set_stocks_movement({}))
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(getResultList());
  }, []);

  const deleteLiveDataInstruments = () => {

    for (const [key, value] of Object.entries(deleteOptions)) {
      if (value.length)
        remove_instrument(key, value);
    }
  }
  
  const updateExpiryMultiple = async (exp) => {

    const BODY = {
      instruments: selectedItems,
      expiry: exp
    }

    try {
      const resp = await socketAPI.post("instruments/change-expiry-multiple", BODY);
    
      if (resp && resp.data && resp.data.success){
        const errorList = resp.data.errorList ?? [];
        if (errorList.length){
          dispatch(addNotification({
            type: 'info',
            message: `Expiry not changed for ${errorList}...`
          }))
        }
      }

      setAnchorExpiry(null)
      
    } catch (error) {
      console.warn("Error in changing expiry...", error)
    }
  }
  
  return (
    <Box>
      {
        currentUser ?
        <TradeUserPosition/> 
        : <UserPosition />
      }
      <MarketIndices/>

      {/* <CustomLiveDataTable /> */}
      
      <Box sx={{width: '95%', padding: '1rem', margin: '0px auto', position: 'relative'}}>
        <UserDataTable columnVisibility={columnVisibilityModel} setDeleteOptions={setDeleteOptions} setSelectedItems={setSelectedItems} />
      </Box>

      {
        liveDataTables.map((t, i) => 
          t.show &&
          <Box key={`l_d_${i}`} sx={{width: '95%', padding: '1rem', margin: '0px auto', position: 'relative'}}>
            <Box width={'100%'} justifyContent={'center'} display={'flex'}>
              <Typography sx={{cursor:'default'}} fontSize={t.pref.titleFontSize} fontWeight={700} fontFamily={'emoji'} color={t.pref.titleFontColor}>{t.pref.title}</Typography>
            </Box>
            <LiveDataTable key={`liveTab_${t.name}`} pref={t.pref} columnVisibility={columnVisibilityModel} setDeleteOptions={setDeleteOptions} setSelectedItems={setSelectedItems} />
          </Box>
        )
      }

      {
        (Object.values(deleteOptions).some(list => list.length)) > 0 ?
        <>
          <Fab size='medium' color='primary' variant="extended" sx={{position: 'fixed', bottom: 70, right: 35}} onClick={ (e) => setAnchorExpiry(e.currentTarget)} > 
            <ChangeCircleIcon />
          </Fab>
          <Menu anchorEl={anchorExpiry} open={Boolean(anchorExpiry)} onClose={()=> setAnchorExpiry(null)}  MenuListProps={{'aria-labelledby': 'userNameShow' }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
          { 
            expiryOptions.map((d,ulI) => 
              <MenuItem sx={{ minWidth: '150px', justifyContent: 'center' }}  key={`expiryOptions_${ulI}`} onClick={() => updateExpiryMultiple(d) }>{d}</MenuItem>
            )  
          }
              
          </Menu>
          <Fab size='medium' color='primary' variant="extended" sx={{position: 'fixed', bottom: 20, right: 35}} onClick={ deleteLiveDataInstruments} >
            <DeleteSharpIcon /> 
          </Fab>
        </>
        : null
      }

      <SideBar liveTabPref={liveDataTables} setLiveTabPref={setLiveDataTables} visiblityShow={liveDataColumnVisiblityShow}  setVisiblityModel={setColumnVisibilityModel} visiblityModel={columnVisibilityModel}/>

    </Box>
  )
}

export default LiveData;