import React from 'react'
import './LiveDataTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, useGridApiRef} from '@mui/x-data-grid';
import { Select, MenuItem, FormControl, Typography } from '@mui/material';
import { emitDataSocket } from "../../features/slices/socket/socketSlice";
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import {SocketContext}  from '../../utils/SocketContext';
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import _ from 'lodash';
import moment from 'moment-timezone';
import {getSortComparator} from "./dateComparator";
import StackedLineChartSharpIcon from '@mui/icons-material/StackedLineChartSharp';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import PossibleLoss from "../Info/PossibleLoss";

const ExpirySelect = React.memo(({value="", id, pref_type, type }) => {
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const [instExp, setInstExp] = React.useState({})

  const handleOpenExp = (instrument) => {
    setInstExp(instrument)
    dispatch(getExpList(instrument));
  };
  const handleCloseExp = () => {
    dispatch(clearExpList()); 
    setInstExp("");
  };

  const update_instrument_expiry = (event) => {
    const req = {
      "event": "update_preferences",
      "pref_type": pref_type,
      "type": type,
      "instrument": id,
      "expiry": event.target.value
    }
    dispatch(emitDataSocket({ req, socket}))
  }

  return (
    <FormControl variant="standard" fullWidth focused={false} >
      <Select value={value} autoWidth onChange={update_instrument_expiry} open={instExp === id } onOpen={() => handleOpenExp(id)} onClose={handleCloseExp}>
        {
          (instExp === id && expList.length ? expList : [value]).map((d,i)=>
            <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}, (prevProps, nextProps) => prevProps.value === nextProps.value)

const LiveDataTable = ({pref, setDeleteOptions, setSelectedItems, columnVisibility}) => {

  const dispatch = useDispatch();
  const gridRef = useGridApiRef();
  const { socket } = React.useContext(SocketContext);
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const resultData = useSelector((state) => state.events.result.list);
  const resultToday = useSelector((state) => state.events.result.today);
  const [hideFooter, setHideFooter] = React.useState(true)
  const [possibleLoss, setPossibleLoss] = React.useState({})
  const updateInterval  = useSelector((state) => state.socket.updateInterval);
  const start  =  moment.unix(useSelector((state) => state.socket.start)).tz("Asia/Kolkata");
  const end  =  moment.unix(useSelector((state) => state.socket.end)).tz("Asia/Kolkata");
  const isDayOfWeek  = useSelector((state) => state.socket.isDayOfWeek);

  // eslint-disable-next-line
  const throttledSetRows = React.useCallback(
    _.throttle((newRows) => {
      try {
        gridRef.current?.setRows(newRows);
        setHideFooter(newRows.length < pref.pageSize)
      } catch (error) {}
    }, 400),
  [resultData]);

  const openChart = React.useCallback((instrument, expiry, change, price, iv, type) => {
    dispatch(updateChart({ instrument, expiry, change, price, iv, type }));
  }, []);
  
  const show_chain = React.useCallback((instrument, expiry) => {
    dispatch(updateChain({
        "instrument": instrument,
        "expiry": expiry
    }))
  }, []);

  const handleCellDoubleClick = React.useCallback((e, event=null) => {
    if(e.field === 'id'){
      show_chain(e.id, e.row.ex)
    }
    else if(e.field === 'pr'){
      openChart(e.id, e.row.ex, e.row.p_c, e.row.pr, e.row.iv, "C");
    }
    else if(e.field === 'ff_p'){
      openChart(e.id, e.row.ex, e.row.p_c, e.row.pr, e.row.iv, "F");
    }
    else if(e.field === 'iv' && currentUser){
      setPossibleLoss({request: {portfolio: e.id, expiry: e.row.ex}, position: event.currentTarget});
    }
  }, [currentUser]);

  const memoizedColumns = React.useMemo(() => {
    return pref?.column?.map(d => {
      const ob = 
      { 
        field: d.key, 
        flex: d.width ? false : true,
        width: d.width,
        headerName: d.header,
        cellClassName: d.cellClassName ?? "",
        // flex: true,
        minWidth: d.minWidth,
        maxWidth: d.maxWidth,
        description: d.description ?? d.header ?? '', 
        align: 'center',
        headerAlign: 'center',
        type: d.type ?? 'string',
      }
      
      if(d.key === 'ex' && d.type === 'select'){
        ob['renderCell'] = (p) => <ExpirySelect id={p.id} type={pref.type} pref_type={pref.pref_type} value={p.formattedValue} />
      }
      else if(d.key === 'pr' ){
        ob['renderCell'] = (p) => <div title='Double click to open chart !' style={{display: 'flex', justifyContent: 'space-around', cursor: 'pointer'}}><Typography>{p.formattedValue}</Typography> <StackedLineChartSharpIcon sx={{color: 'white'}} onClick={(e) => { e.stopPropagation(); handleCellDoubleClick(p, e);}} /></div>
      }
      else if(d.key === 'ff_p' ){
        ob['renderCell'] = (p) => <div title='Double click to open chart !' style={{display: 'flex', justifyContent: 'space-around', cursor: 'pointer'}}><Typography>{p.formattedValue}</Typography> <StackedLineChartSharpIcon sx={{color: 'white'}} onClick={(e) => { e.stopPropagation(); handleCellDoubleClick(p, e);}} /></div>
      }
      else if(d.key === 'iv' && currentUser){
        ob['renderCell'] = (p) => <div title={'Double click to open Possible Loss !'} style={{display: 'flex', justifyContent: 'space-around', cursor: 'pointer'}}><Typography>{p.formattedValue}</Typography>  {  <AirlineStopsIcon title={'Click to open Possible Loss !'} sx={{color: 'white'}} onClick={(e) => { e.stopPropagation(); handleCellDoubleClick(p, e);}} />}</div>
      }
      else if(d.key === 'r_dt'){
        ob['valueGetter'] = (_, d) => resultData?.[d.id]
        ob['getSortComparator'] = getSortComparator;
      }

      return ob
    }) ?? []
  }, [currentUser]);

  React.useEffect(()=>{
    let interval;
    if (!document.hidden) {
      socket.on(pref.socketEventName, throttledSetRows);
      socket.emit('g_data', pref.type);
      interval = setInterval(() => {
        (isDayOfWeek && moment().tz("Asia/Kolkata").isBetween(start, end)) && socket.emit('g_data', pref.type);
      }, updateInterval); // Update count every second
    }
    return () => {
      interval && clearInterval(interval);
      socket.off(pref.socketEventName);
    }
  }, [document.hidden, updateInterval, throttledSetRows])

  const onRowSelectionModelChange = (params) => {
    setDeleteOptions((o)=>{
      const obj = {...o}

      if (!params.length){
        delete obj[pref.type]
      }else{
        obj[pref.type] = params
      }
      return obj

    })

   setSelectedItems([...params])
  }

  const rowClass = React.useCallback((p) => {
    if (pref?.cellClassFn){
      return (resultToday?.includes(p.id) ? 'textclr-yellow' : p?.row?.[pref?.cellClassFn] > 0 ? 'textclr-green' : 'textclr-red');
    }
    return (resultToday?.includes(p.id) ? 'textclr-yellow' : pref?.color ? `textclr-${pref.color}` : '');
  }, [pref, resultToday])

  React.useEffect(()=>{
    gridRef.current?.setColumnVisibilityModel(columnVisibility);
  },[columnVisibility]);

  return (
    <>
      <DataGrid
        apiRef={gridRef}
        initialState={{
          pagination: { paginationModel: { pageSize: pref.pageSize } },
        }}
        sx={{fontSize: pref?.fontSize || '1.2rem', fontWeight: pref?.fontWeight || 400, margin: '0px auto', '.MuiDataGrid-virtualScroller': {
            overflowY: 'auto !important',
            // example of maxHeight
            maxHeight: pref.height,
            minHeight: 100,
          },
          '.MuiDataGrid-iconButtonContainer': {
            width: '0px !important'
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
        }} 
        rowHeight={pref?.RowHeight || 25} 
        columnHeaderHeight={ pref?.columnHeight || 25}
        columns={memoizedColumns} 
        onCellDoubleClick={handleCellDoubleClick}
        checkboxSelection
        onRowSelectionModelChange={onRowSelectionModelChange}
        getRowClassName={rowClass}
        hideFooter={hideFooter}
        pageSizeOptions={[30,50,100]}
        // disableVirtualization
      />
      <PossibleLoss possibleLossParams={possibleLoss} setPossibleLoss={setPossibleLoss}/>
    </>
  )
}

export default LiveDataTable