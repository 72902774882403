
import React from 'react'
import LiveData from "../Pages/LiveData/LiveData";
import MWPL from "../Pages/MWPL/MWPL";
import Events from "../Pages/Events/Events";
import Flows from '../Pages/Flows/Flows';
import PCR from '../Pages/PCR/PCR';
import News from '../Pages/News/News';
import StocksAlert from '../Pages/StocksAlert/StocksAlert';
// import Historical from '../Pages/Historical/Historical';
import ChangePassword from "../Auth/ChangePassword/ChangePassword";
import { useDispatch, useSelector } from 'react-redux';
import { updateEngagement, updateStartTime, updateEngagementDB } from "../../features/slices/engagement/engagementSlice";
import { getCheckLogin } from '../../features/slices/users/userSlice';
import { useSnackbar } from 'notistack';
import { playNotificationSound } from "../CustomSnackbarProvider/CustomSnackbarComponents";

const Home = () => {
  const dispatch = useDispatch();
  const currentTab  = useSelector((state) => state.tab.tab);
  const { pnlUpdates } = useSelector(state => state.notifications);
  const [pnlIndex, setPnlIndex]  = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const engagementTime  = useSelector((state) => state.engagement.engagementTime);
  const startTime  = useSelector((state) => state.engagement.startTime);
  // const [notification, setNotification] = React.useState(null);

  React.useEffect(() => {
    if (pnlUpdates.length >= pnlIndex+1) {
      const p = pnlUpdates[pnlIndex]
      playNotificationSound()
      enqueueSnackbar(p.message, { variant: p.type, transitionDuration: {timeout: p.type === "success" ? 2000 : 3000}})
      setPnlIndex(pnlIndex+1);
    }
  }, [pnlUpdates, setPnlIndex, enqueueSnackbar, pnlIndex])

  const handleVisibilityChange = React.useCallback((change) => {
    if (change) {
      dispatch(updateEngagement(engagementTime + ~~(Date.now() - startTime)));
    } else {
      dispatch(getCheckLogin())
      dispatch(updateStartTime())
    }
  }, [startTime, engagementTime]);

  React.useEffect(() => {
    handleVisibilityChange(document.hidden)
  }, [document.hidden]);

  const sendEngagementData = React.useCallback(() => {
    if(startTime !== null){
      const timeSpent = ~~(Date.now() - startTime);
      dispatch(updateEngagementDB({engagement: ~~((engagementTime + timeSpent)/1000)}));
    }
    
  }, [startTime, engagementTime]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', sendEngagementData);

    return () => {
      window.removeEventListener('beforeunload', sendEngagementData);
    };
  }, [engagementTime, sendEngagementData]);

  const renderContent = () => {
    switch (currentTab) {
      case 'live':
        return <LiveData />;
      case 'chg_pw':
        return <ChangePassword />;
      case 'mwpl':
        return <MWPL />;
      case 'eve':
        return <Events />;
      case 'flo':
        return <Flows />;
      // case 'his':
      //   return <Historical />;
      case 'pcr':
        return <PCR />;
      case 'news':
        return <News />;
      case 'ale':
        return <StocksAlert />;
      default:
        return <p>Error in rendering data...</p>;
    }
  }

  return renderContent();
}

export default Home;