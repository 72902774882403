import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Tabs, Tab, FormControl, MenuItem, Select } from '@mui/material';
import { histAPI, kiteAPI } from "../../utils/axiosInstance";
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";

import LineChart from "../Charts/LineChart";

const options = [
    {
        label:"1d",
        req: {
            days: 1,
            aggregateWindow: "2m"
        }
    },
    {
        label:"3d",
        req: {
            days: 3,
            aggregateWindow: "6m"
        }
    },
    {
        label:"5d",
        req: {
            days: 5,
            aggregateWindow: "10m"
        }
    },
    {
        label:"7d",
        req: {
            days: 7,
            aggregateWindow: "14m"
        }
    },
    {
        label:"15d",
        req: {
            days: 15,
            aggregateWindow: "30m"
        }
    },
    {
        label:"30d",
        req: {
            days: 30,
            aggregateWindow: "1h"
        }
    },
]

const chartTypeOptions = [
    { name: "IV", id: 'iv'},
    { name: "Skew", id: 'skew'},
    { name: "Std Price", id: 'st_price'},
    { name: "OI", id: 'oi'},
    { name: "PCR OI", id: 'oi_rto'},
    { name: "PCR Vol", id: 'vol_rto'},
    { name: "None", id: "none"},
]

const Chart = ({instrument = "", expiry= "", change=0, price=0, iv=null, type="F"}) => {
    const dispatch = useDispatch();
    const [rowData, setRowData] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [addonChart, setAddonChart] = React.useState(localStorage.getItem('addon_chart_fut') ?? "iv");
    const [exp, setExp] = React.useState(expiry);
    const [close, setClose] = React.useState(0);
    const expList  = useSelector((state) => state.search.indicesExp.list);

    React.useEffect(()=>{
        dispatch(getExpList(instrument));
        return () => dispatch(clearExpList());
    }, [instrument, dispatch])

    const getChartData = React.useCallback(async()=>{
        if (instrument){
            const data = await histAPI.post('charts/get-data', {
                bucket: type === "F" ? instrument : "CASH",
                measurement: type === "F" ? exp : instrument,
                field: type === "F" ? (addonChart !== "none" ? ["price", addonChart] : ["price"]) : ["price"],
                ...options[tabIndex]["req"],
                type
            })
            if (data.status === 200 && data.data.success){
                setRowData(data.data.data)
            }else{
                setRowData([])
            }
        }
    }, [instrument, exp, tabIndex, addonChart, type])

    const getOhlc = React.useCallback(async()=>{
        if (instrument && exp){
            const data = await kiteAPI.get(`/instrument/get-ohlc/${instrument+exp}FUT`, {});
            if (data.data.success){
                setClose(data.data?.data?.close ?? 0);
            }else{
                setClose(0);
            } 
        }else{
            setClose(0);
        }
    }, [instrument, exp])

    React.useEffect(()=>{
        getChartData();
    }, [addonChart, getChartData]);

    React.useEffect(()=>{
        getOhlc();
    }, [getOhlc]);

    const changeChartType = (c) => {
        localStorage.setItem('addon_chart_fut', c.target.value)
        setAddonChart(c.target.value)
    }

    return (
        <Box sx={{width: '75vw'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', borderBottom: 1, borderColor: 'divider', alignItems: 'center'}}>
                    <Box sx={{ width: '50%' }}>
                        {change ? 
                        <Typography variant='body1'>{instrument} <span style={{color:(change >= 0)? '#22c55e' : '#f72b2b'}}> {price} {`(${(change >= 0)?'+':''}${change}%)`}</span> {iv ? <Typography variant='body1'>IV: {iv}</Typography>: null}</Typography>
                        : null}
                    </Box>
                    <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }} alignItems={'center'}>
                       {
                            type === "F" ?
                            <>
                                <FormControl variant="standard" focused={false} >
                                    <Select size='small' sx={{paddingX: '0.5rem'}} value={exp} onChange={(e)=> setExp(e.target.value)}>
                                        {
                                            (expList).map((d, i)=>
                                            <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" focused={false} >
                                    <Select sx={{paddingX: '0.5rem'}} title='Addon chart...' size='small' value={addonChart} placeholder="Addon chart..." onChange={changeChartType}
                                    >
                                        {
                                            chartTypeOptions.map((c, i)=> (
                                                <MenuItem key={`type_sel_${i}`} value={c.id}>{c.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </>
                        : null
                        }
                    </Box>
                </Box>
                <Tabs centered value={tabIndex} onChange={(e, v)=> setTabIndex(v)} aria-label="MWPL tabs">
                    {
                        options.map((d, i)=>(
                            <Tab sx={{textTransform :"none"}} label={d.label} value={i}/>
                        ))
                    }
                </Tabs>
                <Box>
                    {
                        rowData.length ? 
                        <LineChart data={rowData} change={change} fields={type === "F" ? (addonChart !== "none" ? ["price", addonChart] : ["price"]) : ["price"]} close={close} /> : 
                        <Typography sx={{textAlign:'center'}}>Loading...</Typography>
                    }
                </Box>
            </Box>
            
        </Box>
    )
}

export default Chart;