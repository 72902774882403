import React from 'react'
import './OptionChain.css';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, Slider, Typography, Stack, FormControl, Select, MenuItem, Switch, Button, TextField } from '@mui/material';
import {SocketContext}  from '../../utils/SocketContext';
import { useSelector, useDispatch } from 'react-redux';
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import { placeOrders } from "../../features/slices/users/userSlice";
import { placeOrderMarket } from "../../features/slices/trade_user/tradeUserSlice";
import { updateChain } from "../../features/slices/table/tablePrefrenceSlice";
import moment from 'moment-timezone';
import _ from 'lodash';

const chain_local = require('../../jsons/tables/chain_ui.json');

const OptionChain = ({chainInstrument, chainExp}) => {
  
  const dispatch = useDispatch();
  const gridRef = useGridApiRef();
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const { socket } = React.useContext(SocketContext);
  const [oneLot, setOneLot] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [showExp, setShowExp] = React.useState(false);
  const [chainData, setChainData] = React.useState({ lotSize: 0, fairPrice: 0, iv: 0, f_lot: 0});
  // const [opt_Exp, setOpt_Exp] = React.useState([]);
  const [order, setOrder] = React.useState([]);
  const [fut, setFut] = React.useState({});
  const [orderLot, setOrderLot] = React.useState(1);
  const [orderAgress, setOrderAgress] = React.useState(0.05);
  const [rowData, setRowData] = React.useState([]);
  const updateInterval  = useSelector((state) => state.socket.updateInterval);
  const start  =  moment.unix(useSelector((state) => state.socket.start)).tz("Asia/Kolkata");
  const end  =  moment.unix(useSelector((state) => state.socket.end)).tz("Asia/Kolkata");
  const isDayOfWeek  = useSelector((state) => state.socket.isDayOfWeek);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const [deltaRange, setDeltaRange] = React.useState([chain_local.deltaFilterMin, chain_local.deltaFilterMax]);
  
  const memoizedColumns = React.useMemo(() => {
    return chain_local?.column.map(d => (
      { 
        field: d.key,
        headerName: d.header, 
        description: d.header, 
        // width: d.width ?? 100,
        flex: true,
        minWidth: d.minWidth,
        maxWidth: d.maxWidth,
        headerClassName: '', 
        align: 'center',
        headerAlign: 'center',
        type: d.type ?? 'string',
      }
    ))
  }, []);

  React.useEffect(()=>{
    const filterArr = rowData.filter((d)=> { const ad = Math.abs(d.d); return(ad >=deltaRange[0] && ad <=deltaRange[1]) } )
    if(fut.id){
      filterArr.splice(0,0,fut)
    }
    gridRef.current?.setRows(filterArr.map((v) => {
      const lot = v["it"] === "XX" ? chainData.f_lot : chainData.lotSize;
      if (oneLot){
        return ({ ...v, "o": Number.isInteger(v["o"]) && (v["o"]/lot), "vg": Math.round(v["vg"]*lot), "t": v["t"]*lot, "dv": v["dv"]*lot });
      }else{
        return v
      }
    }));
  }, [oneLot, deltaRange, rowData]);
  
  const throttledSetRows = React.useCallback(_.throttle(
    (newRows) => {
      const _c = newRows?._c ?? [];
      setFut(newRows?._fi ?? {});
      setRowData(_c);
      setChainData({...chainData, fairPrice: newRows?.ffp, iv:newRows?._i, lotSize:(newRows?.lt || 0), f_lot:(newRows?.flt ?? 0)});
      setLoading(false);
    }, 500)
  ,[]);

  const handleOpenExp = () => {
    dispatch(getExpList(chainInstrument));
    setShowExp(true)
  };
  
  const handleCloseExp = () => {
    setShowExp(false)
    dispatch(clearExpList());
  };

  const update_pref_exp = (event) => {
    dispatch(updateChain({
      "instrument": chainInstrument,
      "expiry": event.target.value
    }))
    socket.emit("update_preferences", {
      "pref_type": "chain",
      "instrument": chainInstrument,
      "expiry": event.target.value
    });
  }

  React.useEffect(()=>{
    let interval;
    if (!document.hidden) {
      interval = setInterval(() => {
        (isDayOfWeek && moment().tz("Asia/Kolkata").isBetween(start, end)) && socket.emit('g_data', chain_local.type);
      }, updateInterval); // Update count every second
    }
    
    return () => {
      interval && clearInterval(interval);
    }
  }, [document.hidden, updateInterval])

  React.useEffect(()=>{
    socket.on(chain_local.socketEventName, throttledSetRows);
    return () =>{
      socket.emit("update_preferences", {
        "pref_type": "dechain"
      });
      socket.off(chain_local.socketEventName);
    }
  // eslint-disable-next-line
  }, [])

  React.useEffect(()=>{
    if(chainInstrument && chainExp){
      setLoading(true)
      socket.emit("update_preferences", {
        "pref_type": "chain",
        "instrument": chainInstrument,
        "expiry": chainExp
      });
    }
      
  }, [chainInstrument, chainExp]);

  const RowStyling = (params) => {
    const odelta = Math.abs(params.row.d)
    return `textclr-opt-${params.row.color} ${( odelta >= chain_local.deltaFocusMin && odelta <=chain_local.deltaFocusMax) ? "chain-focus" : ""}`
  }

  const onSelectChange = React.useCallback(()=>{
    const newOrder = []
    gridRef.current?.getSelectedRows()?.forEach(row => {
      newOrder.push({
        "instrument": row.id,
        "price": row.p
      });
    })
    setOrder(newOrder);
  }, []);

  const updatePrice = (e) => {
    const value = e.target.value;
    order[0].price = value;
    setOrder([...order]);
  }

  const placeOdr = (qty) => {
    const body = order.map((d)=>{
      return {
        "instrument": d.instrument, 
        "qty": qty, 
        "price": d.price
      }
    })
    dispatch(placeOrders(body))
    setOrder([])
    gridRef.current?.setRowSelectionModel([])
  }
  
  const placeOdrMarket = (qty) => {
    const body = {
      "instruments": order.map((d)=>d.instrument), 
      "qty": qty, 
      "agress": parseFloat(orderAgress),
      "user": currentUser
    }

    dispatch(placeOrderMarket(body))
    setOrder([])
    gridRef.current?.setRowSelectionModel([])
  }

  return (
    <Box sx={{width:'auto', minWidth:'80vw' , px:1}}>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Typography component={'span'} alignItems='center' color={'#f472b6'} display={'flex'}>{chainInstrument}</Typography>
          <FormControl variant="standard" focused={false} >
            <Select value={chainExp} open={showExp} onOpen={() => handleOpenExp()} onClose={handleCloseExp} onChange={update_pref_exp}>
              {
                (showExp && expList.length ? expList : [chainExp]).map((d, i)=>
                  <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          <Typography component={'span'} marginX={2} alignItems='center' display={'flex'}>FUTPrc: {chainData.fairPrice ? parseFloat(chainData.fairPrice).toFixed(2): '-'}</Typography>
          <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>IV: {chainData.iv ? parseFloat(chainData.iv).toFixed(2): '-'}</Typography>
          <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Underlying Lot: {chainData.f_lot}</Typography>
          <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Options Lot: {chainData.lotSize}</Typography>
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <DataGrid
          apiRef={gridRef}
          loading={loading}
          sx={{fontSize: chain_local?.fontSize || '1.2rem', fontWeight: chain_local?.fontWeight || 400, '.MuiDataGrid-virtualScroller': {
            overflowY: 'auto !important',
            maxHeight: '75vh',
            minHeight: 100,
          } }} 
          rowHeight={chain_local?.RowHeight || 25} 
          columnHeaderHeight={chain_local?.columnHeight || 25}
          onRowSelectionModelChange={onSelectChange}
          columns={memoizedColumns}
          initialState={chain_local.initialState}
          checkboxSelection
          getRowClassName={RowStyling}
          hideFooter
          disableVirtualization
        />
      </Box>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            One Lot: <Switch onChange={(e,d) => setOneLot(d)} checked={oneLot} />
          </Box>
          <Stack sx={{ width:250 }} alignItems={'center'} direction="row">
            <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Delta: </Typography>
            {/* <Typography marginRight={2} alignItems='center' display={'flex'}> {deltaRange[0]}</Typography> */}
            <Slider step={0.01} onChangeCommitted={(e,v)=> setDeltaRange([...v])} valueLabelDisplay={'auto'} value={deltaRange} min={0} max={1} />
            {/* <Typography marginRight={2} alignItems='center' display={'flex'}> {deltaRange[1]}</Typography> */}
          </Stack>
        </Box>
      </Box>
      { 
        order.length > 0 ? 
        <Box display={'flex'} width={'100%'} marginBottom={"0.5rem"}>
          <Box display={'flex'} width={'100%'} justifyContent={"center"}>
            <Button variant="outlined" sx={{color: "#22c55e", mx:"0.5rem" }} onClick={()=> currentUser ? placeOdrMarket(1*orderLot) : placeOdr(orderLot*chainData.lotSize) } >Buy</Button>
            <TextField size='small' sx={{mx: '0.5rem'}} id="odrLot" type='number' label="Lots" variant="outlined" value={orderLot} onChange={(e)=> setOrderLot(e.target.value) } inputProps={{min:0}} />
            {
              currentUser ?
              <TextField size='small' sx={{mx: '0.5rem'}} inputProps={{step: 0.05 }} type='number' id="odrAgress" label={"Agress"} variant="outlined" value={orderAgress} onChange={(e) => setOrderAgress(e.target.value)} />
              : order.length === 1 ?
              <TextField size='small' sx={{mx: '0.5rem'}} inputProps={{step: 0.05}} type='number' id="odrPrice" label={"Price"} variant="outlined" value={order[0].price} onChange={updatePrice} /> 
              : null
            }
            <Button variant="outlined" sx={{color: "#f72b2b", mx:"0.5rem" }} onClick={()=> currentUser ? placeOdrMarket(-1*orderLot) : placeOdr(-1*orderLot*chainData.lotSize) } >Sell</Button>
          </Box>
        </Box> 
        : null
      }
    </Box>
  )
}

export default OptionChain