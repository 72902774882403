import React from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, IconButton, Modal, TextField, Grid, Button, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { kiteAPI } from "../../../utils/axiosInstance";
import { createAlert } from "../../../features/slices/alert/TransitionAlertsSlice";
import { useDispatch, useSelector } from 'react-redux';
import { splitDateTime } from'../../../utils/globalFunctions';
import { rowGrouper } from "../../Tables/tableFunctions";
import FilterListIcon from '@mui/icons-material/FilterList';

const today = new Date().toISOString().split('T')[0];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '85vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'black',
  height: 'auto',
  outline:'none',
  // boxShadow: 24,
  p: '0.2rem', 
};

export default function News(){
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const [rows, setRows] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState('ASC');
  const [groupKeys, setGroupKeys] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date(new Date().setDate(1)));
  const [endDate, setEndDate] = React.useState(new Date());
  const [mType, setMType] = React.useState(null);
  const [formData, setFormData] = React.useState({
    headline: "",
    date_time: null,
    related_to: "",
    link: "",
    description: "",
    reaction: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  React.useEffect(() => {
    setGroupKeys([...Object.keys(rows).sort((a, b) => {
      const comparator = sort === 'ASC' ? 1 : -1;
      const diff = new Date(a)-(new Date(b));
      if(diff > 0) return -comparator 
      if(diff < 0) return comparator
      return 0;
    })]);// eslint-disable-next-line
  },[sort, rows])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const f = {
      ...formData,
      date_time: formData.date_time.toISOString()
    }
    if(mType === 'add'){
      setLoading(true);
      const res = await kiteAPI.post('events/add-news', f);
      setLoading(false);
      if (res.status === 200){
        if(res.data.message){
          dispatch(createAlert({message: res.data.message, type: res.data?.success ? 'success' : 'error', timeout: 2000}))
        }
        if(res.data?.success){
          getNews()
          setMType(null)
          setFormData({})
        }       
      }else{
        dispatch(createAlert({message: "Some issue occured...", type: 'error', timeout: 2000}))
      }
    }else if(mType === 'edit'){
      setLoading(true);
      const res = await kiteAPI.post('events/update-news', f);
      setLoading(false);
      if (res.status === 200){
        if(res.data.message){
          dispatch(createAlert({message: res.data.message, type: res.data?.success ? 'success' : 'error', timeout: 2000}))
        }
        if(res.data?.success){
          getNews()
          setMType(null)
          setFormData({})
        }       
      }else{
        dispatch(createAlert({message: "Some issue occured...", type: 'error', timeout: 2000}))
      }
    }

  };

  const getNews = React.useCallback(async()=>{
    setLoading(true);
    const res = await kiteAPI.get(`events/get/news-list?fromDate=${startDate.toISOString().split('T')[0]}&toDate=${endDate.toISOString().split('T')[0]}`);
    setLoading(false);
    if (res.status === 200){
      if(res.data?.success){
        const d = rowGrouper(res.data.data.map(d => {const dateTime=splitDateTime(d.date_time); return({...d, date: dateTime.formattedDate, time: dateTime.formattedTime}) } ), 'date');
        setRows(d);
      }else{
        dispatch(createAlert({message: res.data.message, type:'error', timeout: 2000}))
        return;
      }
    }// eslint-disable-next-line
  }, [startDate, endDate, dispatch])

  React.useEffect(()=>{
    getNews()
  }, [getNews])

  return (
    <>
      <Box padding='.8rem' width='100%' display='flex' justifyContent='flex-end' alignItems='center' flexDirection='row'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker slotProps={{ textField: { size: 'small' } }} format="dd-MM-yyyy" onChange={(date) => setStartDate(date)} sx={{paddingX:.5}} portalId="root-portal" startDate={startDate} endDate={endDate} autoComplete='false' label="Start Date" value={startDate} />
          <DatePicker slotProps={{ textField: { size: 'small' } }} format="dd-MM-yyyy" onChange={(date) => { if(!startDate){ setStartDate(new Date()) } setEndDate(date)}} portalId="root-portal" sx={{paddingX:.5}} startDate={startDate} endDate={endDate} minDate={startDate} value={endDate} label="End Date" autoComplete='false' />
        </LocalizationProvider>
        <IconButton title='Sort...' sx={{paddingX:.5, transform: sort === 'ASC'? 'rotate(0deg)': 'rotate(180deg)'}} onClick={()=> { sort === 'ASC' ? setSort('DESC') : setSort('ASC')}}>
          <FilterListIcon/>
        </IconButton>
        {
          currentUser ? 
          <IconButton title='Add...' sx={{paddingX:.5}} onClick={()=> { setMType('add'); setFormData({...formData, date_time: new Date()}) }}>
            <AddIcon/>
          </IconButton>
          : null
        }
      </Box>

      <Box width='90vw' height={'90vh'} margin='0 auto'>
        {
          groupKeys.map((row) =>
            <Accordion key={`date-${row}`} defaultExpanded={row === today}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component={'span'} fontSize={'1.2rem'} color={'primary'}>{row}</Typography></AccordionSummary>
                <AccordionDetails>
                  {
                    rows?.[row]?.map((d, idb)=>
                      <Accordion key={`time-${row}-${idb}`}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography fontSize={'1.2rem'} fontWeight={700} color={'#C9DF8A'} variant={'p'}><Typography component={'span'} color={'secondary'} fontSize={'1.2rem'}>{d.time}</Typography> {d?.headline}</Typography></AccordionSummary>
                        <AccordionDetails><Typography fontSize={'1.2rem'} style={{ whiteSpace: "pre-line", wordWrap: "break-word"}} component="pre">{d?.description ? d?.description + "\n"+ d?.reaction: d?.reaction || "-"}</Typography></AccordionDetails>
                        {
                          currentUser ?
                          <AccordionActions>
                            <Button title='Edit News' onClick={()=> {setMType('edit'); setFormData({...d, date_time: new Date(d.date_time)}) }}>Edit</Button>
                          </AccordionActions>
                          :null
                        }
                      </Accordion>
                    )
                  }
                </AccordionDetails>
            </Accordion>
          )
        }
      </Box>

      <Modal open={Boolean(mType)} onClose={() => {if(mType === 'edit'){setFormData({})} setMType(null); }} aria-labelledby="News Modal" aria-describedby="Add, edit, view news.">
        <Box sx={style}>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '80vw', margin: '0 auto', my: 4 }}>
            <Grid container spacing={1} >
              <Grid item xs={8}>
                <TextField fullWidth size='small' placeholder="Headline..." label="Headline" name="headline" variant="outlined" value={formData.headline} onChange={handleChange} required/>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker slotProps={{ textField: { size: 'small', fullWidth: true } }} format="dd-MM-yyyy hh:mm a" onChange={(e) =>setFormData((d)=> ({...d, date_time: e}))} value={formData?.date_time} name="date_time" label="Date Time"/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth size='small' placeholder="Related to..." label="Related To" name="related_to" variant="outlined" value={formData.related_to} onChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth size='small' placeholder="Source/link..." label="Link" name="link" variant="outlined" value={formData.link} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth size='small' InputProps={{rows: 9, multiline: true, inputComponent: 'textarea'}} placeholder="Description..." label="Description" name="description" variant="outlined" value={formData.description} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth size='small' InputProps={{rows: 2, multiline: true, inputComponent: 'textarea'}} placeholder="Reaction..." label="Reaction" name="reaction" variant="outlined" value={formData.reaction} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" disabled={!formData.headline} variant="contained" color="primary" fullWidth>{ formData?.id?"Update":"Save"}</Button>
              </Grid>
            </Grid>
          </Box>  
        </Box>
      </Modal>
    </>
  )
}           