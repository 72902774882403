import React from 'react';
import './LiveDataTable.css';
import { Box } from '@mui/material';
import { kiteAPI } from "../../utils/axiosInstance";
import moment from 'moment';
import { DataGrid} from '@mui/x-data-grid';

const today = moment().format('DD MMM YYYY');
const comp = (a, b) => moment(a, 'DD MMM YYYY').diff(moment(b, 'DD MMM YYYY'))
const columns = [
    {
        field: "id",
        type: "string",
        width: 300,
        align: 'center',
        headerAlign: 'center',
        headerName: "Date",
        getSortComparator: (o) => o === 'asc' ? comp : -comp,
    },
    {
        field: "portfolios",
        flex: true,
        align: 'center',
        headerAlign: 'center',
        type: "string",
        headerName: "Companies"
    }
]

export default function QtrResults() {
    const [list, setList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const getData = React.useCallback(async () => {
        setLoading(true);
        const response = await kiteAPI.get(`events/get/results?fromDate=${fromDate}&toDate=${toDate}&list=true`);
        if (response.data?.success && response.data?.data){
            setList(Object.entries(response.data.data?.reduce((acc, { eventDate, portfolio }) => {
                if (!acc[eventDate]) {
                  acc[eventDate] = [];
                }
                acc[eventDate].push(portfolio);
                return acc;
            }, {})).map(([date, portfolios]) => ({ id: date, portfolios: (portfolios.toString()).replace(/,/g,", ")})));
            
        }else{
            setList([])
        }
        setLoading(false);
    }, [fromDate, toDate])

    React.useEffect(()=>{
        getData()
    }, [getData])

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {
            loading ? <Box sx={{textAlign: 'center', fontSize: '2rem'}}>Loading...</Box> : 
            list ? <DataGrid initialState={{sorting: {sortModel: [{ field: 'id', sort: 'asc' }]}}} sx={{fontSize: '1.1rem'}} rowHeight={25} columnHeaderHeight={32}  columns={columns} rows={list} getRowClassName={(row) => today === row.id ? 'textclr-yellow': ''} /> : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No F&O Stocks...</Box>
        }
    </Box>
  )
}