import React from 'react';
import { Autocomplete, Box, Checkbox, MenuItem, Select, TextField } from '@mui/material';
import { histAPI } from "../../../utils/axiosInstance";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import globals from '../../../globals';

export default function PCR(){
  const [underlying, setUnderlying] = React.useState({ bucket : "", list: [] });
  const [loadingUL, setLoadingUL] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [eventsCheck, setEventsCheck] = React.useState(false);
  const [eventsData, setEventsData] = React.useState([]);
  // const [expiry, setExpiry] = React.useState({ value : "", list: [] });
  // const [loadingExp, setLoadingExp] = React.useState(false);

  const getUnderlying = React.useCallback(async(search)=>{
    setLoadingUL(true);
    const data = await histAPI.post('charts/get-options', {
      search: search
    })
    setLoadingUL(false);
    if (data.status === 200 && data.data.success){
      setUnderlying((o)=>({...o, list: data.data.data}))
    }else{
      setUnderlying((o)=>({...o, list: []}))
    }
  }, [])

  // const getUnderlyingExp = React.useCallback(async(bucket)=>{
  //   setLoadingUL(true);
  //   const data = await histAPI.post('charts/get-options', {
  //     bucket
  //   })
  //   setLoadingUL(false);
  //   if (data.status === 200 && data.data.success){
  //     setExpiry((o)=>({...o, list: data.data.data}))
  //   }else{
  //     setExpiry((o)=>({...o, list: []}))
  //   }
  // }, [underlying.bucket])

  // React.useEffect(() => {
  //   if(underlying.bucket){
  //     getUnderlyingExp(underlying.bucket)
  //   }else{
  //     setExpiry((o)=>({...o, list: []}))
  //   }
  // }, [underlying.bucket])

  return (
    <Box >
      <Box display={'flex'} alignItems={'center'} padding={0.5}>
        <div style={{width:'50%',  display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>
          <Autocomplete
            clearOnEscape
            clearOnBlur
            size='small'
            onChange={(e, v) => setUnderlying((o)=> ({...o, bucket: v}))}
            loading={loadingUL}
            loadingText={`Just a sec...`}
            options={underlying.list}
            sx={{ width: 250 }}
            renderInput={(params) => <TextField onChange={(e) => getUnderlying(e.target.value) } placeholder={'Select underlying...'} {...params} />}
          />
        </div>
        <div style={{width:'50%',  display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row'}}>
          <FormControlLabel control={<Checkbox value={eventsCheck} onChange={()=> setEventsCheck(!eventsCheck)} />} label="Events" />
        </div>
      </Box>
      {
        eventsCheck &&
        <Box padding={0.5} width={'100%'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} flexDirection={'row'}>
            <Select
              id="event-type"
              size='small'
              value={eventsData[globals?.events?.type?.label]}
              placeholder={globals?.events?.type?.label}
              // eslint-disable-next-line
              onChange={(event)=> setEventsData(Object.values({...eventsData, [0]:event.target.value}))}
            >
              {
                Object.keys(globals?.events?.type?.list).map((e, i) => 
                  <MenuItem id={`et_${i}`} value={e}>{globals?.events?.type?.list[e]}</MenuItem>
                )
              }
            </Select>
            {
              eventsData.map((key, i) =>
                <Select
                  id={`event-type-${key}-${i}`}
                  size='small'
                  value={eventsData[i]}
                  placeholder={key}
                  onChange={(event)=> setEventsData(Object.values({...eventsData, [i+1]:event.target.value}))}
                >
                  {
                    Object.keys(globals?.events[eventsData[i]]?.list).map((e, i) => 
                      <MenuItem id={`et_${i}`} value={e}>{globals?.events[eventsData[i]]?.list[e]}</MenuItem>
                    )
                  }
                </Select>
              )
            }
        </Box>
      }
      <Box padding={0.5} width={'100%'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} flexDirection={'row'}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            portalId="root-portal"
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            customInput={<TextField size='small' sx={{m:.5}} autoComplete='off' label="Start Date" variant="outlined" />}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => { if(!startDate){ setStartDate(Date()) } setEndDate(date)}}
            selectsEnd
            portalId="root-portal"
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            customInput={<TextField size='small' sx={{m:.5}} autoComplete='off' label="End Date" variant="outlined" />}
          /> */}
        </LocalizationProvider>
      </Box>

        <div style={{width: '90vw', height: '90vh', bgcolor: 'burlywood'}}>

        </div>
    </Box>
  )
}           