import React, { createContext } from 'react';
import {tradeUser} from '../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { addPnlUpdates } from '../features/slices/alert/TransitionAlertsSlice';
export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [socket, setSocket] = React.useState(io(process.env.REACT_APP_BASE_URL_SOCKET+ 'wait-list', { transports: ['websocket'], path:"/socket", withCredentials: true, autoConnect:false }));
  const [userSocket, setUserSocket] = React.useState(io(process.env.REACT_APP_BASE_URL_SOCKET, { transports: ['websocket'], path:"/user-socket", withCredentials: true, autoConnect:false }));

  const [balances, setBalance] = React.useState({});
  const [splitout_data, setSplitOut] = React.useState({});
  const [takePosition, setTakePosition] = React.useState({});
  const [tradeMarktPositions, setPositions] = React.useState({ tt: {}, fut: {}, inst: [] });

  const disconnectSocket = () =>{
    socket.disconnect();
  }
  const connectSocket = () =>{
    socket.connect();
  }

  const disconnectUserSocket = () =>{
    userSocket.disconnect();
  }
  const connectUserSocket = () =>{
    getBalance();
    userSocket.connect();
  }

  const getBalance = async () => {
    try {
      const response = await tradeUser.get('user/balance');
      if (response.data && response.data.success){
        setBalance(response.data.data); 
      }else{
        setBalance({});
      }
      
    } catch (error) {
      console.error("Error in getting balance: ", error);
      setBalance({});
    }
  }

  React.useEffect(()=>{
    if (userSocket){
      userSocket.on('alert', (data) => {
        const status = data?.status
        if(status === "REJECTED" || status === "CANCELLED" || status === "COMPLETE"){
            if (status !== "REJECTED"){
              getBalance();
            }
            dispatch(addPnlUpdates({message: data?.message ?? "", type: status === "COMPLETE" ? "success" : "error"}));
        }
      });

      userSocket.on('splitout_d', setSplitOut);
      userSocket.on('take_d', setTakePosition);
      userSocket.on('p_up', setPositions);

    }

    return () => {
      if (userSocket) {
        userSocket.off('alert');
        userSocket.off('splitout_d');
        userSocket.off('take_d');
        userSocket.off('p_up');
      }
    };
  },[userSocket]);

  return (
    <SocketContext.Provider value={{socket, userSocket, balances, splitout_data, takePosition, tradeMarktPositions, disconnectSocket, connectSocket, disconnectUserSocket, connectUserSocket}}>
      {children}
    </SocketContext.Provider>
  );
};