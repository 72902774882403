import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {socketAPI} from '../../../utils/axiosInstance';
import { createAlert } from "../alert/TransitionAlertsSlice";

export const getList = createAsyncThunk('portfolio/list', async (req) => {
  const response = await socketAPI.post('instruments/search', req);
  return response.data;
});

export const getExpList = createAsyncThunk('portfolio/exp-list', async (req) => {
  const response = await socketAPI.get(`instruments/expiry-list/${req}`);
  return response.data;
});

export const addInstrument = createAsyncThunk('instruments/add', async (req, {dispatch}) => {
  const BODY = {
    instrument: req,
    type: "user"
  }
  const response = await socketAPI.post('instruments/add-wait-list', BODY);
  if(response.data.message)
    dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  return response.data;
});

export const searchSlice = createSlice({
  name: "loader",
  initialState: {
    instruments : {
        "status": "init",
        "list": []
    },
    indicesExp : {
        "status": "init",
        "list": []
    },

  },
  reducers: {
    clearExpList: (state, action) => {
      state.indicesExp.list = []
      state.indicesExp.status = "init"
    },
    clearSearch: (state, action) => {
      state.instruments.list = []
      state.instruments.status = "init"
    }
  }, 
  extraReducers: (builder) => {
    builder
    .addCase(getList.pending, (state) => {
      state.instruments.status = 'loading';
    })
    .addCase(getList.fulfilled, (state, action) => {
        if (action.payload && action.payload.success && action.payload.data){
            state.instruments.status = 'succeeded';
            state.instruments.list = [...action.payload.data];
        }else{
            state.instruments.status = 'failed';
        }
    })
    .addCase(getList.rejected, (state, action) => {
        state.instruments.status = 'failed';
    })
    .addCase(getExpList.pending, (state) => {
      state.indicesExp.status = 'loading';
    })
    .addCase(getExpList.fulfilled, (state, action) => {
        if (action.payload && action.payload.success && action.payload.data){
            state.indicesExp.status = 'succeeded';
            state.indicesExp.list = action.payload.data;
        }else{
            state.indicesExp.status = 'failed';
        }
    })
    .addCase(getExpList.rejected, (state, action) => {
        state.indicesExp.status = 'failed';
    })
  }
});

export const { clearExpList, clearSearch } = searchSlice.actions;

export default searchSlice.reducer;