import { createSlice } from "@reduxjs/toolkit";

export const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    alerts: [],
    notification: [],
    dialogs: [],
    pnlUpdates: [],
    stocks_movement: {},
  },
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type,
        timeout: action.payload.timeout ?? null
      });
    },
    set_stocks_movement: (state, action) => {
      if (typeof action.payload === 'object'){
        const d = action.payload;
        state.stocks_movement = {...state.stocks_movement, ...d};
      }
    },
    removeAlert: (state, action) => {
      state.alerts.splice(action.payload.index, 1);
    },
    addPnlUpdates: (state, action) => {
      state.pnlUpdates.push({
        message: action.payload.message,
        type: action.payload.type
      });
    },
    removePnlUpdates: (state, action) => {
      state.pnlUpdates.splice(action.payload.index, 1);
    },
    removeDialogAlert: (state, action) => {
      if(action.payload?.index)
        state.alerts.splice(action.payload.index, 1);
      else
        state.dialogs.pop();
    },
    addDialogAlert: (state, action) => {
      if (action.payload.title || action.payload.message)
        state.dialogs.push(action.payload)
      
    },
    addNotification: (state, action) => {
      state.notification.push({
        "type": action.payload.type,
        "message": action.payload.message,
        "read": false
      })
      
    },
    readNotification: (state, action) => {
      for (const i in state.notification){
        state.notification[i].read = true
      }
    }
  }
});

export const { createAlert, removeAlert, readNotification, addNotification, addDialogAlert, removeDialogAlert, addPnlUpdates, set_stocks_movement } = AlertSlice.actions;

export default AlertSlice.reducer;